
import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchRequests ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchRequests',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_requests', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Request` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  addRequest ({ commit, state }, request) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set lot parameters
      request.id = this.getters.get_uuid()
      request.created = Date.now()
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createRequest',
          params: {
            id: request.id,
            numero: request.numero,
            program: JSON.stringify(request.program),
            lot: JSON.stringify(request.lot),
            user: JSON.stringify(request.user),
            prospect: JSON.stringify(request.prospect),
            status: request.status,
            created: request.created
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_request', request)
            var mess = 'Request edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit request in database!'
            commit('status_error', err)
          }
        })
    })
  },
  editRequest ({ commit, state }, request) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // validate request become reservation
      if (request.status === 1) {
        // set lot parameters
        const Today = Date.now()
        const lastId = this.getters.get_uuid()
        var lastNum = this.getters.get_reservation_last_numero() + 1
        const reservation = {
          id: lastId,
          numero: lastNum,
          program: {
            id: request.program.id,
            numero: request.program.numero,
            shortname: request.program.shortname
          },
          lot: {
            id: request.lot.id,
            uid: request.lot.uid,
            numero: request.lot.numero,
            actedprice: request.lot.price,
            extra: '',
            datereserv: 0,
            law: request.lot.law,
            lawyears: request.lot.lawyears
          },
          user: {
            id: request.user.id,
            username: request.user.username,
            firstname: request.user.firstname,
            lastname: request.user.lastname
          },
          prospect: {
            id: request.prospect.id,
            numero: request.prospect.numero,
            numero2: 0,
            firstname: request.prospect.firstname,
            lastname: request.prospect.lastname,
            email: request.prospect.email,
            phone: request.prospect.phone,
            endorsement1: 0,
            endorsement2: 0,
            deposit: 0,
            letter: 0,
            datesru: 0,
            acknowledgment: 0,
            associate: 0
          },
          notary: {
            id: '',
            numero: 0,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            involvement: 0,
            send: 0,
            sendoffer: 0,
            cash: 0,
            notif: 0,
            notifdate: 0,
            sign: 0,
            signdate: 0
          },
          bank: {
            id: '',
            numero: 0,
            lastname: '',
            adress: '',
            phone: '',
            counselor: {
              firstname: '',
              email: '',
              phone: ''
            },
            loan: {
              agreement: 0,
              agreementdate: 0,
              offer: 0,
              offerdate: 0,
              amount: 0,
              contribution: 0,
              duration: 0,
              rate: 0,
              guarantee: ''
            }
          },
          remarks: {
            notarycost: 0,
            managementcost: 0,
            text: ''
          },
          status: 1,
          created: Today,
          api: {
            docusign: '',
            ar24: ''
          }
        }
        this.dispatch('addReservation', reservation)
      }
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateRequest',
          params: {
            id: request.id,
            numero: request.numero,
            program: JSON.stringify(request.program),
            lot: JSON.stringify(request.lot),
            user: JSON.stringify(request.user),
            prospect: JSON.stringify(request.prospect),
            status: request.status,
            created: request.created
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_request', request)
            var mess = 'Request edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit request in database!'
            commit('status_error', err)
          }
        })
    })
  },
  removeRequest ({ commit, state }, request) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'deleteRequest',
          params: {
            id: request.id
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('delete_request', request)
            var mess = 'Request removed from database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to delete request in database!'
            commit('status_error', err)
          }
        })
    })
  }
}

export default Actions
