<template>
  <section>
    <label v-if="showcreate" for="create">
      <input type="checkbox" class="toggle" id="create" v-model="create">
      <small>Création rapide</small>
    </label>
    <form v-if="create" @submit.prevent="add()">
      <p v-if="filter <= 2">
        <span class="small normal">type: </span>
        <select id="filter" v-model.number="filter">
          <option :value="1">client</option>
          <option :value="2">société</option>
        </select>
        <i class="icon-select"></i>
      </p>
      <p v-if="filter !== 2 && filter !== 4">
        <span class="small normal">Prénom: </span>
        <input type="text" v-model="newProspect.firstname" />
      </p>
      <p>
        <span class="small normal">Nom: </span>
        <input type="text" v-model="newProspect.lastname" />
      </p>
      <p v-if="filter === 2">
        <span class="small normal">Gérant: </span>
        <input type="text" v-model="newProspect.firstname" />
      </p>
      <p>
        <span class="small normal">Email: </span>
        <input type="text" v-model="newProspect.email" />
      </p>
      <p>
        <span class="small normal">Tel: </span>
        <input type="text" v-model="newProspect.phone" />
      </p>
      <p>
        <span class="small normal">Ville: </span>
        <input type="text" v-model="newProspect.city" />
      </p>
      <p>
        <button type="submit">AJOUTER</button>
      </p>
    </form>
    <section v-else>
      <span v-if="numero > 0">
        <em class="badge">
          <router-link :to="{ path: '/prospect/details/'+numero }">
            {{ data.lastname || 'n/a' }} {{ data.firstname || '' }}
          </router-link>
        </em>
        <u class="icon-delete" v-on:click="showcreate = true, numero = 0, choose()"></u>
      </span>
      <section v-else>
        <ul class="filter">
          <li class="filtersearch">
            <input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i>
          </li>
        </ul>
        <small class="normal verysmall">Total: {{ prospects.length }} - Résultat: {{ prospectSearch.length }}</small>
        <ul class="scroll finder">
          <li :class="{'selected': numero === prop.numero}" v-for="(prop, p) in prospectSearch.slice(0, this.limit)" :key="'P' + p"
          v-on:click="change(prop), choose()">
            {{ prop.lastname }} {{ prop.firstname }}
          </li>
          <li class="more" v-if="limit < prospectSearch.length" v-on:click="more()">+ Afficher plus</li>
        </ul>
      </section>
    </section>
  </section>
</template>

<script>

export default {
  name: 'findProspect',
  props: {
    filter: Number,
    type: String,
    prospect: {
      default: 0, type: Number
    }
  },
  data () {
    return {
      compagny: '0',
      create: false,
      showcreate: true,
      newProspect: {
        firstname: '', lastname: '', email: '', phone: '', city: ''
      },
      search: '',
      numero: this.prospect,
      data: {
        firstname: '', lastname: ''
      },
      limit: 10
    }
  },
  computed: {
    prospects: function () {
      var propects = this.$store.getters.get_prospects()
      if (this.filter <= 2) return propects.filter(n => n.list === 1 || n.list === 2)
      return propects.filter(n => n.list === this.filter)
    },
    prospectSearch: function () {
      if (this.search) {
        return this.prospects.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.firstname.toLowerCase().includes(v) ||
            item.lastname.toLowerCase().includes(v) ||
            item.email.toLowerCase().includes(v)
          )
        })
      }
      return this.prospects
    }
  },
  created () {
    // set the prospect firstname & lastname
    if (this.numero > 0) {
      var data = this.prospects.find(p => Number(p.numero) === this.numero)
      this.data.firstname = data.firstname
      this.data.lastname = data.lastname
      this.showcreate = false
    }
  },
  methods: {
    change: function (prospect) {
      this.numero = prospect.numero
      this.data.lastname = prospect.lastname
      this.data.firstname = prospect.firstname
      this.showcreate = false
    },
    choose: function (event) {
      this.$emit('send', this.numero, this.type)
    },
    more: function () {
      this.limit += 10
    },
    add: function () {
      // prepare payload
      var payload = this.newProspect
      payload.list = this.filter
      this.$store.dispatch('addProspectFromReservation', payload)
        .then((num) => {
          payload.numero = num
          this.change(payload)
          this.choose()
          this.newProspect = {
            firstname: '', lastname: '', email: '', phone: '', city: ''
          }
          this.create = false
        })
        .catch()
    }
  }
}
</script>
