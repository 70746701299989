<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-lot"></i>LOTS</h1>
    </section>
    <section class="lots filter">
      <ul>
        <li class="filterchoice" :class="{'active' : filteredA > 0}">
          <select v-model="filteredA" @change="handleNumero(filteredA)">
            <option value="-1">Programmes: Tous</option>
            <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Type: Tous</option>
            <option v-for="(type, t) in settings.lot.type" :value="t" :key="t">{{ type }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredC">
            <option value="-1">Statut: Tous</option>
            <option v-for="(status, s) in settings.lot.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredD">
            <option value="-1">Lois: Toutes</option>
            <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredE">
            <option value="-1">Prix: Tous</option>
            <option v-for="(range, r) in settings.lot.ranges" :value="r" :key="r">{{ range.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredF">
            <option value="-1">Defisc: Toutes</option>
            <option v-for="(def, d) in settings.lot.defisc" :value="d" :key="d">{{ def.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <!--<li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>-->
        <router-link tag="li" to="/lot/new" class="filteradd"><i class="icon-add"></i> Ajouter un lot</router-link>
        <router-link tag="li" to="/lot/import" class="filteradd"><i class="icon-add"></i> Importer</router-link>
      </ul>
    </section>

    <LotsList :lots="lotSearch" />

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import LotsList from '@/components/LotsList.vue'
import settings from '@/settings.js'

export default {
  name: 'Lots',
  components: {
    Profile,
    LotsList
  },
  data () {
    return {
      settings,
      search: '',
      filteredA: -1,
      filteredB: -1,
      filteredC: -1,
      filteredD: -1,
      filteredE: -1,
      filteredF: -1
    }
  },
  created () {
    this.filteredA = this.numero
  },
  computed: {
    numero: function () {
      return this.$store.getters.meta.menu.numero
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lots: function () {
      return this.$store.getters.get_lots()
    },
    lotProgram: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.lots
      return this.lots.filter(el => el.program.numero === fil)
    },
    lotType: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.lotProgram
      return this.lotProgram.filter(el => el.spec.type === fil)
    },
    lotStatus: function () {
      var fil = Number(this.filteredC)
      if (fil === -1) return this.lotType
      return this.lotType.filter(el => el.status === fil)
    },
    lotLaw: function () {
      var fil = Number(this.filteredD)
      if (fil === -1) return this.lotStatus
      return this.lotStatus.filter(el => el.law === fil)
    },
    lotRange: function () {
      var fil = Number(this.filteredE)
      var range = this.settings.lot.ranges
      if (fil === -1) return this.lotLaw
      return this.lotLaw.filter(
        el => el.spec.price <= range[fil].max && el.spec.price >= range[fil].min)
    },
    lotDefisc: function () {
      var fil = Number(this.filteredF)
      var range = this.settings.lot.defisc
      if (fil === -1) return this.lotRange
      return this.lotRange.filter(
        el => el.spec.defisc <= range[fil].max && el.spec.price >= range[fil].min)
    },
    lotSearch: function () {
      if (this.search) {
        return this.lotDefisc.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v)
          )
        })
      }
      return this.lotDefisc
    }
  },
  methods: {
    handleNumero: function (value) {
      this.$store.commit('numero_program', value)
    }
  }
}
</script>
