import Dashboard from '@/views/Dashboard.vue'
import Mailings from '@/views/Mailings.vue'
import Statistiques from '@/views/Statistics.vue'
import Parametres from '@/views/Settings.vue'
import MonCompte from '@/views/Accounts/Account.vue'
import MonCompteEdit from '@/views/Accounts/Account_edit.vue'
import Error404 from '@/views/Errors/404.vue'
import Closed from '@/views/Errors/Closed.vue'
import Restricted from '@/views/Errors/Restricted.vue'

const route = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { lvl: 1 }
  },
  {
    path: '/mailings',
    name: 'Mailings',
    component: Mailings,
    meta: { lvl: 3 }
  },
  {
    path: '/mailing/:id',
    name: 'Mailing',
    component: Mailings,
    meta: { lvl: 3 }
  },
  {
    path: '/statistiques',
    name: 'Statistiques',
    component: Statistiques,
    meta: { lvl: 3 }
  },
  {
    path: '/parametres',
    name: 'Parametres',
    component: Parametres,
    meta: { lvl: 5 }
  },
  {
    path: '/profile',
    name: 'MonCompte',
    component: MonCompte,
    meta: { lvl: 1 }
  },
  {
    path: '/profile/edit',
    name: 'MonCompteEdit',
    component: MonCompteEdit,
    meta: { lvl: 1 }
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: Restricted,
    meta: { lvl: 1 }
  },
  {
    path: '/closed',
    name: 'Closed',
    component: Closed,
    meta: { lvl: 1 }
  },
  {
    path: '*',
    name: '404',
    component: Error404,
    meta: { lvl: 1 }
  }
]

export default route
