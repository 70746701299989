import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  uploadProgram ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      var numero = payload.numero
      var uploads = payload.uploads
      var result = []
      var count = 0
      // check for upload files
      uploads.forEach(function (element) {
        commit('status_loading')
        var formData = new FormData()
        var file = element[0]
        formData.append('file', file)
        // call the api
        axios({
          method: 'post',
          url: settings.host,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            key: state.auth.key,
            token: state.auth.token,
            function: 'uploadProgram',
            params: {
              program: numero,
              name: element[1],
              type: element[2]
            }
          }
        })
          .then(resp => {
            // check header status code
            if (resp.data.status.success) {
              // prepare array success
              var urlpath = resp.data.data.filename
              var tmpArray = []
              tmpArray.push(element[3])
              tmpArray.push(urlpath)
              result.push('', tmpArray)
              count++
              // success
              var mess = 'File uploaded!'
              commit('status_success', mess)
              if (count === uploads.length) {
                resolve(result)
              }
            } else {
              // error
              var err = 'Upload failed!'
              commit('status_error', err)
            }
          })
      })
    })
  },
  uploadLot ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
    // check for upload files
      commit('status_loading')
      var formData = new FormData()
      var file = payload.upload[0]
      formData.append('file', file)
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'uploadLot',
          params: {
            lot: payload.lotNumero,
            program: payload.programNumero,
            name: payload.upload[1],
            type: payload.upload[2]
          }
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // prepare array success
            var filename = resp.data.data.filename
            // upload success
            var mess = 'File uploaded!'
            commit('status_success', mess)
            resolve(filename)
          } else {
            // error
            var err = 'Upload failed!'
            commit('status_error', err)
          }
        })
    })
  },
  uploadConfig ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      var uploads = payload.uploads
      var result = []
      var count = 0
      // check for upload files
      uploads.forEach(function (element) {
        commit('status_loading')
        var formData = new FormData()
        var file = element[0]
        formData.append('file', file)
        // call the api
        axios({
          method: 'post',
          url: settings.host,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            key: state.auth.key,
            token: state.auth.token,
            function: 'uploadConfig',
            params: {
              name: element[1],
              type: element[2]
            }
          }
        })
          .then(resp => {
            // check header status code
            if (resp.data.status.success) {
              // prepare array success
              var urlpath = resp.data.data.filename
              var tmpArray = []
              tmpArray.push(element[3])
              tmpArray.push(urlpath)
              result.push('', tmpArray)
              count++
              // success
              var mess = 'File uploaded!'
              commit('status_success', mess)
              if (count === uploads.length) {
                resolve(result)
              }
            } else {
              // error
              var err = 'Upload failed!'
              commit('status_error', err)
            }
          })
      })
    })
  }
}

export default Actions
