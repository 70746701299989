<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-lot"></i>ÉDITION LOT</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.thumbnail)" /></figure>
          <p>
            <label for="num">Programme (Obligatoire)</label>
            <select id="program" v-model="program.numero" @click="setProgram()" disabled>
              <option value="">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Identifiants <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="num">Uid du Lot</label>
            <input type="text" size="4" id="num" v-model="lot.uid" disabled />
          </p>
          <p>
            <label for="num">Numéro du lot</label>
            <input type="text" size="4" id="num" v-model="lot.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="name">Lettre du bâtiment</label>
            <input type="text" maxlength="1" size="1" id="name" @keyup="filterBuild(),setProgram()" v-model="lot.spec.building" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <small class="info orange">Info: La modification de certains paramètres d'un lot s'effectue sur la réservation de celui-ci.</small>
            <label for="stat">Statut</label>
            <select id="stat" v-model="lot.status" disabled>
              <option v-for="(status, s) in settings.lot.status" :value="s" :key="s">{{ status }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="law">Dispositif de loi</label>
            <select id="law" v-model="lot.law">
              <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="law">Durée de défiscalisation</label>
            <select id="law" v-model="lot.lawyears" disabled>
              <option v-for="(years, y) in settings.lot.lawyears" :value="y" :key="y">{{ years }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="Number(lot.created) | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Lot</h2>
        <section>
          <p>
            <label for="type">Type</label>
            <select id="type" v-model="lot.spec.type">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="story">Superposition</label>
            <select id="story" v-model="lot.spec.story">
              <option v-for="(lot, st) in settings.lot.story" :value="st" :key="st">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="floor">Étage</label>
            <input type="number" id="floor" v-model="lot.spec.floor" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="size">Surface (m²)</label>
            <input type="number" id="size" step="0.01" v-model="lot.spec.size" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="size">Surface défiscalisable (m²)</label>
            <input type="number" id="size" step="0.01" v-model="lot.spec.total" min="0" />
          </p>
        </section>
        <section>
          <p>
            <label for="exp">Exposition</label>
            <select id="exp" v-model="lot.spec.exposure">
              <option v-for="(expo, e) in settings.lot.exposure" :value="e" :key="e">{{ expo }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Finance</h2>
        <section>
          <p>
            <label for="price">Prix</label>
            <input type="number" step="0.01" id="price" v-model="lot.spec.price" />
          </p>
        </section>
        <section>
          <p>
            <label for="defisc">Coût défisc</label>
            <input type="number" step="0.01" id="defisc" v-model="lot.spec.defisc" />
          </p>
        </section>
        <section>
          <p>
            <label for="loan">Loyer</label>
            <input type="number" step="0.01" id="loan" v-model="lot.spec.loan" />
          </p>
        </section>
        <section>
          <p>
            <label for="fees">Frais de notaire</label>
            <input type="number" step="0.01" id="fees" v-model="lot.spec.notaryfees" />
          </p>
        </section>
        <section>
          <p>
            <label for="parkingprice">Prix du parking</label>
            <input type="number" step="0.01" id="parkingprice" v-model="lot.spec.parkingprice" />
          </p>
        </section>
        <section>
          <p>
            <label for="parkingloan">Loyer du parking</label>
            <input type="number" step="0.01" id="parkingloan" v-model="lot.spec.parkingloan" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Options</h2>
        <small>La valeur par défaut (0) signifie que l'option est indisponible</small>
        <section>
          <p>
            <label for="balcony">Balcon (m²)</label>
            <input type="number" id="balcony" step="0.01" v-model="lot.spec.balcony" />
          </p>
        </section>
        <section>
          <p>
            <label for="varangue">Varangue (m²)</label>
            <input type="number" id="varangue" step="0.01" v-model="lot.spec.varangue" />
          </p>
        </section>
        <section>
          <p>
            <label for="terrace">Terrasse (m²)</label>
            <input type="number" id="terrace" step="0.01" v-model="lot.spec.terrace" />
          </p>
        </section>
        <section>
          <p>
            <label for="logia">Logia (m²)</label>
            <input type="number" id="logia" step="0.01" v-model="lot.spec.logia" />
          </p>
        </section>
        <section>
          <p>
            <label for="garden">Jardin (m²)</label>
            <input type="number" id="garden" step="0.01" v-model="lot.spec.garden" />
          </p>
        </section>
        <section>
          <p>
            <label for="cellar">Numéro cave</label>
            <small>Virgule séparateur si multiples</small>
            <input type="text" id="cellar"  v-model="lot.spec.cellar" />
          </p>
        </section>
        <section>
          <p>
            <label for="parking">Numéro parking</label>
            <small>Virgule séparateur si multiples</small>
            <input type="text" id="parking" v-model="lot.spec.parking" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Téléchargement</h2>
        <section>
          <p>
            <label for="logo">Fichier zip</label>
            <small>.zip .rar &lt; 5Mo</small>
            <input v-if="lot.download === ''" type="file" id="zip" ref="zip" v-on:change="handle('zip','download','rar')"/>
            <em class="verysmall" v-else>{{ lot.download }} <u class="icon-delete" v-on:click="lot.download = ''"></u></em>
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ lot }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit">EDITER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'lotEdit',
  components: {
    Profile,
    InvalidForm
  },
  data () {
    return {
      settings,
      upload: [],
      program: {
        name: '',
        numero: '',
        thumbnail: ''
      },
      lot: {}
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lt: function () {
      return this.$store.getters.get_lot_by_uid(this.$route.params.id)
    }
  },
  created () {
    this.lot = this.lt
    this.program.numero = this.lt.program.numero
    this.setProgram()
  },
  methods: {
    setProgram: function () {
      var prog = Number(this.program.numero)
      if (prog !== 0) {
        var theProgram = this.programs.find(program => program.numero === this.program.numero)
        var build = this.lot.spec.building.toUpperCase()
        if (build === 'E') build = 'EE'
        this.lot.uid = this.program.numero + build + (this.lot.numero < 10 ? '0' : '') + this.lot.numero
        this.lot.program.numero = prog
        this.lot.program.id = theProgram.id
        this.lot.program.shortname = theProgram.shortname
        this.program.thumbnail = theProgram.thumbnail
      } else {
        this.program.numero = ''
        this.program.thumbnail = ''
      }
    },
    filterBuild: function () {
      var build = this.lot.spec.building.replace(/[^A-Za-z]/g, '')
      this.lot.spec.building = build.toUpperCase()
    },
    edit: function () {
      const payload = {
        lot: this.lot,
        upload: this.upload
      }
      this.$store.dispatch('editLotWithUpload', payload)
        .then(() => {
          this.$router.push('/lots')
        })
        .catch()
    },
    handle: function (input, name, type) {
      var file = this.$refs[input].files[0]
      if (file) {
        var newupload = []
        newupload[0] = file
        newupload[1] = name
        newupload[2] = type
        newupload[3] = input
        this.upload = newupload
      }
    },
    validate: function () {
      if (!Number(this.program.numero) > 0) return false
      return true
    },
    image
  }
}
</script>
