<template>
  <section class="lots table">
    <ul>
      <li>
        <label title="Nom du programme" class="sort reset" @click="reset()">PROG</label>
        <label title="Lettre du bâtiment" class="sort" :class="classActive('building')" @click="sortby('building')">BAT</label>
        <label title="Étage du lot" class="sort" :class="classActive('floor')" @click="sortby('floor')">NIV</label>
        <label title="Numéro du lot">NUM</label>
        <label title="Type de lot" class="sort" :class="classActive('type')" @click="sortby('type')">TYPE</label>
        <label title="Surface habitable du lot" class="sort" :class="classActive('size')" @click="sortby('size')">SH</label>
        <label title="Avec balcon">BAL</label>
        <label title="Avec varangue">VAR</label>
        <label title="Avec terrasse">TER</label>
        <label title="Avec logia">LOG</label>
        <label title="Surface défiscalisable">SD</label>
        <label title="Avec jardin">JARD</label>
        <label title="Avec cave (numero box)">CAV</label>
        <label title="Avec parking (numero place)">PARK</label>
        <label title="Prix du parking">PP</label>
        <label title="Prix total" class="sort" :class="classActive('price')" @click="sortby('price')">PRIX</label>
        <label title="Montant frais de notaire">NOT</label>
        <label title="Montant du loyer">LOY</label>
        <label title="Dispositif de défiscalisation">LOI</label>
        <label title="Prix défiscalisable">DEFISC</label>
        <label title="Exposition">EXP</label>
        <label title="Fichier & doc de vente">DOC</label>
        <label title="Status du lot">STAT</label>
        <label title="Demande en attente">FIL</label>
        <label title="Action">ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(lot, l) in sorted" :key="l" v-on:dblclick="router(lot.uid)">
        <span class="bold">
          <router-link :to="{ path: '/programme/details/'+lot.program.numero }">
            {{ short(lot.program.numero) || lot.program.numero | capitalize }}
          </router-link>
        </span>
        <span class="small">{{ lot.spec.building || 'n/a' }}</span>
        <span class="small">{{ lot.spec.floor | floor }}</span>
        <span class="bold">{{ lot.numero }}</span>
        <span class="small">{{ lot.spec.type | type }}<sup v-if="lot.spec.story"> {{ lot.spec.story | story }}</sup></span>
        <span class="bold">{{ lot.spec.size }}m²</span>
        <span>{{ lot.spec.balcony | square }}</span>
        <span>{{ lot.spec.varangue | square }}</span>
        <span>{{ lot.spec.terrace | square }}</span>
        <span>{{ lot.spec.logia| square }}</span>
        <span >{{ lot.spec.total }}m²</span>
        <span>{{ lot.spec.garden | square }}</span>
        <span>{{ lot.spec.cellar | option }}</span>
        <span>{{ lot.spec.parking | option }}</span>
        <span>{{ lot.spec.parkingprice.toLocaleString() }} €</span>
        <span class="bold">{{ lot.spec.price.toLocaleString() }} €</span>
        <span>{{ lot.spec.notaryfees.toLocaleString() }} €</span>
        <span>{{ lot.spec.loan.toLocaleString() }} €</span>
        <span class="bold">
          {{ lot.law | law }}
          <sup class="small" v-if="lot.lawyears"> {{ lot.lawyears | lawyears }}</sup>
        </span>
        <span>{{ lot.spec.defisc.toLocaleString() }} €</span>
        <span>{{ lot.spec.exposure | exp }}</span>
        <span><i v-if="lot.download === ''">x</i><a v-else :href=" settings.upload + lot.download" target="_blank">doc</a></span>
        <span v-if="lot.reservation.numero" class="badge status" :class="status(lot.status)">
          <router-link :to="{ path: '/reservation/edit/'+lot.reservation.numero }">
            {{ lot.status | lot_status }}
          </router-link>
          <sup :class="{'expired' : dayLeft(lot.reservation.date) < 0, 'expired' : dayLeft(lot.reservation.date) === 'exp'}" v-if="lot.status === 1">{{ dayLeft(lot.reservation.date) }}</sup>
        </span>
        <span v-else class="badge" :class="status(lot.status)">{{ lot.status | lot_status }}</span>
        <span>
          {{ standby(lot.uid) }}
        </span>
        <span>
          <router-link v-if="lot.status === 0" tag="i" :to="{ path: '/reservation/new/lot/'+lot.uid }" class="icon-reserv"></router-link>
          <router-link v-else tag="i" :to="{ path: '/demande/new/lot/'+lot.uid }" class="icon-alert icon-alert-g"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/lot/details/'+lot.uid }" class="icon-see"></router-link>&nbsp;
          <!--<router-link tag="i" :to="{ path: '/lot/duplicate/'+lot.uid}" class="icon-dupli"></router-link>&nbsp;-->
          <router-link tag="i" :to="{ path: '/lot/edit/'+lot.uid }" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/lot/del/'+lot.uid }" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="lots.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < lots.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
import settings from '@/settings.js'

export default {
  name: 'LotsList',
  props: {
    lots: Array
  },
  data () {
    return {
      settings,
      dir: true,
      sort: 'uid',
      sortdeep: false,
      limit: 50
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      var deep = this.sortdeep
      return this.lots.slice().sort(function (a, b) {
        if (deep) {
          if (dir) return (a.spec[sort] > b.spec[sort]) ? 1 : -1
          return (a.spec[sort] < b.spec[sort]) ? 1 : -1
        }
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      }).slice(0, this.limit)
    },
    programs: function () {
      return this.$store.getters.get_programs()
    }
  },
  methods: {
    short: function (value) {
      var program = this.programs.find(p => Number(p.numero) === Number(value))
      return program.shortname
    },
    status: function (value) {
      return 'st' + value
    },
    sortby: function (value) {
      this.sortdeep = true
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sortdeep = false
      this.sort = 'uid'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (uid) {
      this.$router.push('/lot/details/' + uid)
    },
    dayLeft: function (date) {
      const today = Date.now()
      const diff = today - date
      var days = 7 - (diff / (1000 * 3600 * 24))
      if (days < -15) return 'exp'
      return parseFloat(days).toFixed(0)
    },
    standby: function (uid) {
      return this.$store.getters.get_requests_standby_by_uid(uid).length
    },
    more: function () {
      this.limit += 50
    }
  }
}
</script>
