<template>
  <section class="lots table limited">
    <ul>
      <li>
        <label class="sort reset" @click="reset()">NUM</label>
        <label title="Nom du programme">PROG</label>
        <label title="Lettre/numéro du bâtiment">BAT</label>
        <label title="Type de lot" class="sort" :class="classActive('type')" @click="sortby('type')">TYPE</label>
        <label title="Étage du lot" class="sort" :class="classActive('floor')" @click="sortby('floor')">NIV</label>
        <label title="Prix total" class="sort" :class="classActive('price')" @click="sortby('price')">PRIX</label>
        <label title="Prix défiscalisable">DEFISC</label>
        <label title="Surface du lot" class="sort" :class="classActive('size')" @click="sortby('size')">SURF</label>
        <label title="Surface défiscalisable">SD</label>
        <label title="Avec balcon">BAL</label>
        <label title="Avec varangue">VAR</label>
        <label title="Avec terrasse">TER</label>
        <label title="Avec logia">LOG</label>
        <label title="Avec jardin">JARD</label>
        <label title="Avec cave (numero box)">CAV</label>
        <label title="Avec parking (numero place)">PARK</label>
        <label title="Prix du parking">PP</label>
        <label title="Montant du loyer">LOY</label>
        <label title="Dispositif de défiscalisation">LOI</label>
        <label title="Montant frais de notaire">NOT</label>
        <label title="Exposition">EXP</label>
        <label title="Status du lot">STAT</label>
        <label title="Fichier & doc de vente">DOC</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(lot, l) in sorted" :key="l">
        <span class="small">{{ lot.numero }}</span>
        <span class="bold">
          {{ short(lot.program.numero) || lot.program.numero | capitalize }}
        </span>
        <span class="small">{{ lot.spec.building.toUpperCase() || 'n/a' }}</span>
        <span class="bold">{{ lot.spec.type | type }}<sup v-if="lot.spec.story"> {{ lot.spec.story | story }}</sup></span>
        <span class="small">{{ lot.spec.floor | floor }}</span>
        <span class="bold">{{ lot.spec.price.toLocaleString() }} €</span>
        <span>{{ lot.spec.defisc.toLocaleString() }} €</span>
        <span class="bold">{{ lot.spec.size }}m²</span>
        <span>{{ lot.spec.total }}m²</span>
        <span>{{ lot.spec.balcony | square }}</span>
        <span>{{ lot.spec.varangue | square }}</span>
        <span>{{ lot.spec.terrace | square }}</span>
        <span>{{ lot.spec.logia| square }}</span>
        <span>{{ lot.spec.garden | square }}</span>
        <span>{{ lot.spec.cellar | option }}</span>
        <span>{{ lot.spec.parking | option }}</span>
        <span>{{ lot.spec.parkingprice.toLocaleString() }} €</span>
        <span>{{ lot.spec.loan.toLocaleString() }} €</span>
        <span class="bold">
          {{ lot.law | law }}
        </span>
        <span>{{ lot.spec.notaryfees.toLocaleString() }} €</span>
        <span>{{ lot.spec.exposure | exp }}</span>
        <span v-if="lot.status > 0" class="badge st1">{{ status(lot.status) }}</span>
        <span v-else class="badge callmeback st0" title="Demander une option" @click="interested(lot)">
          {{ status(lot.status) }}
        </span>
        <span class="download">
          <i v-if="lot.download === ''">x</i>
          <u v-else @click="down(lot.download),updateStat(lot.id)" title="Télécharger les documents">Docs</u>
          <!--<a v-else :href="settings.upload + lot.download"  target="_blank">doc</a>-->
          </span>
      </li>
    </ul>
    <p v-if="lots.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < lots.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
import settings from '@/settings.js'

export default {
  name: 'LotsList',
  props: {
    lots: Array
  },
  data () {
    return {
      settings,
      dir: true,
      sort: 'uid',
      sortdeep: false,
      limit: 50
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      var deep = this.sortdeep
      // delete all lots with status greater than 'optionned'
      var mylots = this.lots.filter(l => l.status < 2)
      return mylots.slice().sort(function (a, b) {
        if (deep) {
          if (dir) return (a.spec[sort] > b.spec[sort]) ? 1 : -1
          return (a.spec[sort] < b.spec[sort]) ? 1 : -1
        }
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      }).slice(0, this.limit)
    },
    programs: function () {
      return this.$store.getters.get_programs()
    }
  },
  methods: {
    short: function (value) {
      var program = this.programs.find(p => Number(p.numero) === Number(value))
      return program.shortname
    },
    status: function (value) {
      if (value > 0) return 'Optionnée'
      return 'Disponible'
    },
    sortby: function (value) {
      this.sortdeep = true
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sortdeep = false
      this.sort = 'uid'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    more: function () {
      this.limit += 50
    },
    down: function (url) {
      const a = document.createElement('a')
      a.href = this.settings.upload + url
      a.style = 'visibility:hidden'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    updateStat: function (id) {
      this.$store.dispatch('editStatLot', id)
        .then(() => {})
        .catch()
    },
    interested: function (lot) {
      var conf = confirm('Veuillez confirmer votre demande d\'option\n' +
      'concernant le lot numéro: ' + lot.numero)
      // check confirmation then send by mail
      if (conf) {
        this.$store.dispatch('requestLot', lot)
          .then(() => {})
          .catch()
      }
    }
  }
}
</script>
