
import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchProspects ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchProspects',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_prospects', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Prospect` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  addProspect ({ commit, state }, prospect) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set prospect parameters
      prospect.id = this.getters.get_uuid()
      prospect.created = Date.now()
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createProspect',
          params: {
            id: prospect.id,
            numero: prospect.numero,
            firstname: prospect.firstname,
            lastname: prospect.lastname,
            email: prospect.email,
            list: prospect.list,
            created: prospect.created,
            personal: JSON.stringify(prospect.personal),
            contact: JSON.stringify(prospect.contact),
            program: JSON.stringify(prospect.program)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_prospect', prospect)
            var mess = 'Prospect added to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to add prospect in database!'
            commit('status_error', err)
          }
        })
    })
  },
  addProspectFromReservation ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // phone formated to string (ex:#0298571020)
      var phone = '#' + payload.phone.split('.').join('').split(' ').join('').split('#').join('')
      // set prospect parameters
      var prospect = {
        id: '',
        numero: 0,
        firstname: payload.firstname,
        lastname: payload.lastname,
        email: payload.email,
        list: payload.list,
        created: 0,
        personal: {
          birthday: 0, birthyear: '', age: '', gender: 1, situation: 0, job: '', income: 0, children: 0
        },
        contact: {
          address: '', city: payload.city, region: '', zipcode: '', country: '', phone: phone, mobile: ''
        },
        program: {
          numero: 0, shortname: '', type: 0, budget: 0
        }
      }
      prospect.id = this.getters.get_uuid()
      prospect.created = Date.now()
      prospect.numero = this.getters.get_prospect_last_numero() + 1
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createProspect',
          params: {
            id: prospect.id,
            numero: prospect.numero,
            firstname: prospect.firstname,
            lastname: prospect.lastname,
            email: prospect.email,
            list: prospect.list,
            created: prospect.created,
            personal: JSON.stringify(prospect.personal),
            contact: JSON.stringify(prospect.contact),
            program: JSON.stringify(prospect.program)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_prospect', prospect)
            var mess = 'Prospect added to database!'
            commit('status_success', mess)
            resolve(prospect.numero)
          } else {
            // error
            var err = 'Failed to add prospect in database!'
            commit('status_error', err)
          }
        })
    })
  },
  editProspect ({ commit, state }, prospect) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateProspect',
          params: {
            id: prospect.id,
            numero: prospect.numero,
            firstname: prospect.firstname,
            lastname: prospect.lastname,
            email: prospect.email,
            list: prospect.list,
            created: prospect.created,
            personal: JSON.stringify(prospect.personal),
            contact: JSON.stringify(prospect.contact),
            program: JSON.stringify(prospect.program)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_prospect', prospect)
            var mess = 'Prospect edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit prospect in database!'
            commit('status_error', err)
          }
        })
    })
  },
  removeProspect ({ commit, state }, prospect) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'deleteProspect',
          params: {
            id: prospect.id
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('delete_prospect', prospect)
            var mess = 'Prospect removed from database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to delete prospect in database!'
            commit('status_error', err)
          }
        })
    })
  }
}

export default Actions
