<template>
  <section class="container">
    <h3 class="title"><i class="icon-programme"></i>NOUVEAUTÉS OCÉANIC</h3>
    <section class="card">
      <section class="city">{{ prog.location.city || 'ville' | uppercase}}</section>
      <span class="status">{{ prog.status | prog_status }}</span>
      <router-link tag="section" :to="{ path: '/programme/details/'+prog.numero }" class="image">
        <img alt="" :src="image(prog.thumbnail)" />
      </router-link>
      <section class="inside">
        <span class="title">{{ prog.name }}</span>
        <span class="location"><i class="icon-location"></i> À {{ prog.location.city || 'ville' }}, {{ prog.location.district || 'quartier' }}</span>
        <ul>
          <li>DU<span>{{ prog.lot.type.min | type }} au {{ prog.lot.type.max | type }}</span></li>
          <li>DE<span>{{ prog.lot.size.min }}m² à {{ prog.lot.size.max }}m²</span></li>
          <li>À PARTIR DE<span>{{ prog.lot.price.min.toLocaleString() }} €</span></li>
        </ul>
        <p>{{ prog.summary }}</p>
        <router-link tag="button" :to="{ path: '/programme/details/'+prog.numero }">EN SAVOIR PLUS</router-link>
      </section>
    </section>
  </section>
</template>

<script>
import { image } from '@/helpers.js'

export default {
  name: 'ProgrammesCardPartner',
  props: {
    prog: Object
  },
  data () {
    return {
    }
  },
  methods: {
    image
  }
}
</script>
