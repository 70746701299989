<template>
  <section class="syntheses table">
    <ul>
      <li>
        <label class="sort reset" @click="reset()" title="Programme">PROG</label>
        <label class="sort" :class="classActive('uid')" @click="sortby('uid', 'lot')" title="Numéro du lot">LOT</label>
        <label>STAT</label>
        <label>TYPE</label>
        <label>PRIX</label>
        <label title="Dispositif de défiscalisation">LOI</label>
        <label title="Société">SOC</label>
        <label title="Partenaire">PART</label>
        <label title="Client">CLI</label>
        <label title="Date réservation">RES</label>
        <label title="Date d'envois SRU">SRU</label>
        <label title="Date accord de pret">ADP</label>
        <label title="Date offre de pret">ODP</label>
        <label title="Date notification notaire">NOTIF</label>
        <label title="Date de l'acte">ADV</label>
        <label title="Frais de notaire offert">FN</label>
        <label title="Frais de gestion offert">FG</label>
        <label title="Commentaires">COM</label>
        <label>ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(synthese, r) in sorted" :key="r" v-on:dblclick="router(synthese.numero)" >
        <span class="bold">
          <router-link :to="{ path: '/programme/details/'+synthese.program.numero }">
          {{ short(synthese.program.numero) || synthese.program.numero | capitalize }}
          </router-link>
        </span>
        <span>
          <router-link :to="{ path: '/lot/details/'+synthese.lot.uid }">
          {{ synthese.lot.numero }}</router-link>
        </span>
        <span class="badge" :class="status(synthese.status)">
          {{ synthese.status | reservation_status }}
          <sup class="" :class="{'expired' : dayLeft(synthese.created) < 0, 'expired' : dayLeft(synthese.created) === 'exp' }" v-if="synthese.status === 1">{{ dayLeft(synthese.created) }}</sup>
        </span>
        <span>{{ lot(synthese.lot.uid).spec.type | type }}<sup v-if="lot(synthese.lot.uid).spec.story"> {{ lot(synthese.lot.uid).spec.story | story }}</sup></span>
        <span class="bold" :title="lot(synthese.lot.uid).spec.size +' m²'">
          {{ lot(synthese.lot.uid).spec.price.toLocaleString() }} €
        </span>
        <span class="bold">
          {{ synthese.lot.law | law }}
          <sup class="small" v-if="synthese.lot.lawyears"> {{ synthese.lot.lawyears | lawyears }}</sup>
        </span>
        <span>
          {{ user(synthese.user.username).compagny || 'n/a' }}
        </span>
        <span :title="user(synthese.user.username).lastname + ' ' + user(synthese.user.username).firstname">
          <router-link :to="{ path: '/utilisateur/details/'+synthese.user.username }">
            {{ user(synthese.user.username).lastname || 'n/a' }}
          </router-link>
        </span>
        <span :title="prospect(synthese.prospect.numero).lastname + ' ' + prospect(synthese.prospect.numero).firstname">
          <router-link :to="{ path: '/prospect/details/'+synthese.prospect.numero }">
            {{ prospect(synthese.prospect.numero).lastname || 'n/a'  }}
          </router-link>
          <sup :title="prospect(synthese.prospect.numero2).lastname+' '+prospect(synthese.prospect.numero2).firstname" v-if="synthese.prospect.numero2">
            <router-link :to="{ path: '/prospect/details/'+synthese.prospect.numero2 }">²</router-link>
          </sup>
        </span>
        <span>{{ synthese.lot.datereserv | shortDate }}</span>
        <span>{{ synthese.prospect.datesru | shortDate }}</span>
        <span>{{ synthese.bank.loan.agreementdate | shortDate }}</span>
        <span>{{ synthese.bank.loan.offerdate | shortDate }}</span>
        <span>{{ synthese.notary.notifdate | shortDate }}</span>
        <span>{{ synthese.notary.signdate | shortDate }}</span>
        <span class="badge" ><i v-if="synthese.remarks.notarycost" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="badge" ><i v-if="synthese.remarks.managementcost" class="round green">✔</i><i v-else class="round red">✘</i></span>
        <span class="remarktext">{{ synthese.remarks.text }}</span>
        <span>
          <router-link tag="i" :to="{ path: '/reservation/edit/' + synthese.numero }" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/reservation/del/' + synthese.numero }" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="syntheses.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < syntheses.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
export default {
  name: 'syntheseList',
  props: {
    syntheses: Array
  },
  data () {
    return {
      dir: true,
      nested: 'lot',
      sort: 'uid',
      limit: 50
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots()
    },
    users: function () {
      return this.$store.getters.get_users()
    },
    prospects: function () {
      return this.$store.getters.get_prospects()
    },
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      var nest = this.nested
      return this.syntheses.slice().sort(function (a, b) {
        if (nest === '') {
          if (dir) return (a[sort] > b[sort]) ? 1 : -1
          return (a[sort] < b[sort]) ? 1 : -1
        } else {
          if (dir) return (a[nest][sort] > b[nest][sort]) ? 1 : -1
          return (a[nest][sort] < b[nest][sort]) ? 1 : -1
        }
      }).slice(0, this.limit)
    },
    programs: function () {
      return this.$store.getters.get_programs()
    }
  },
  methods: {
    short: function (value) {
      var program = this.programs.find(p => Number(p.numero) === Number(value))
      return program.shortname
    },
    status: function (value) {
      return 'st' + value
    },
    sortby: function (value, nest = '') {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
        this.nested = nest
      }
    },
    reset: function () {
      this.sort = 'created'
      this.dir = false
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (numero) {
      this.$router.push('/reservation/edit/' + numero)
    },
    dayLeft: function (date) {
      const today = Date.now()
      const diff = today - date
      var days = 7 - (diff / (1000 * 3600 * 24))
      if (days < -15) return 'exp'
      return parseFloat(days).toFixed(0)
    },
    more: function () {
      this.limit += 50
    },
    lot: function (uid) {
      return this.lots.find(el => el.uid === uid)
    },
    user: function (user) {
      var u = this.users.find(el => el.username === user)
      if (u) return u.meta
      // deleted from user database
      return {
        lastname: 'unknown',
        firstname: '',
        compagny: ''
      }
    },
    prospect: function (prosp) {
      var p = this.prospects.find(el => el.numero === prosp)
      if (p) return p
      // deleted from user database
      return {
        lastname: 'unknown',
        firstname: ''
      }
    }
  }
}
</script>
