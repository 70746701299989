<template>
  <section class="table by8">
    <ul>
      <li>
        <label class="sort reset" @click="reset()">PARTENAIRE</label>
        <label><i style="color: var(--yellow);">⬤</i></label>
        <label><i style="color: var(--reserved);">⬤</i></label>
        <label><i style="color: var(--financed);">⬤</i></label>
        <label><i style="color: var(--acted);">⬤</i></label>
        <label class="sort" :class="classActive('count')" @click="sortby('count')">NOMBRE</label>
        <label class="sort" :class="classActive('ca')" @click="sortby('ca')">PRIX</label>
        <label>P.C</label>
      </li>
    </ul>
    <ul>
      <li v-for="(user, u) in sorted" :key="u">
        <span>{{ user.lastname }} {{ user.firstname | initial }}</span>
        <span>{{ user.status.count[1] }}</span>
        <span>{{ user.status.count[2] }}</span>
        <span>{{ user.status.count[3] }}</span>
        <span>{{ user.status.count[4] }}</span>
        <span>{{ user.count }}</span>
        <span>{{ user.ca.toLocaleString() }} €</span>
        <span>{{ user.pc }} %</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'StatisticsProgramPartner',
  props: {
    numero: Number
  },
  data () {
    return {
      types: 13,
      dir: false,
      sort: 'count'
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    totals: function () {
      var totals = 0
      this.lots.forEach(q => {
        totals = totals + q.spec.price
      })
      return totals
    },
    reservations: function () {
      return this.$store.getters.get_reservation_by_program_num(this.numero)
    },
    users: function () {
      return this.$store.getters.get_users()
    },
    usersProgram: function () {
      var usersProgram = []
      var users = this.users
      var totals = this.totals
      this.reservations.forEach(r => {
        users.forEach(u => {
          if (r.user.username === u.username) {
            var index = usersProgram.map(b => b.username).indexOf(u.username)
            var mylot = this.$store.getters.get_lot_by_uid(r.lot.uid)
            // console.log(r.status)
            var payload = {
              username: u.username,
              status: {
                count: [0, 0, 0, 0, 0],
                price: [0, 0, 0, 0, 0]
              },
              count: 0,
              ca: 0,
              pc: 0,
              firstname: u.meta.firstname,
              lastname: u.meta.lastname
            }
            if (index > -1) {
              payload.status.count[r.status] = usersProgram[index].count + 1
              payload.status.price[r.status] = Number(usersProgram[index].ca) + Number(mylot.spec.price)
              payload.count = usersProgram[index].count + 1
              payload.ca = Number(usersProgram[index].ca) + Number(mylot.spec.price)
              payload.pc = Number(((payload.ca / totals) * 100).toFixed(2))
              usersProgram.splice(index, 1, payload)
            } else {
              payload.status.count[r.status] = 1
              payload.status.price[r.status] = mylot.spec.price
              payload.count = 1
              payload.ca = mylot.spec.price
              payload.pc = Number(((payload.ca / totals) * 100).toFixed(2))
              usersProgram.push(payload)
            }
          }
        })
      })
      return usersProgram
    },
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      return this.usersProgram.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      })
    }
  },
  methods: {
    sortby: function (value) {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sort = 'count'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    }
  }
}
</script>
