<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-prospect"></i>PROSPECTS</h1>
    </section>
    <section class="prospects filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredA">
            <option value="-1">Listes: Toutes</option>
            <option v-for="(list, l) in settings.prospect.list" :value="l" :key="l">{{ list }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link tag="li" to="/prospect/new" class="filteradd"><i class="icon-add"></i> Ajouter un prospect</router-link>
      </ul>
    </section>
    <ProspectsList :prospects="prospectSearch"/>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ProspectsList from '@/components/ProspectsList.vue'
import settings from '@/settings.js'

export default {
  name: 'Prospects',
  components: {
    Profile,
    ProspectsList
  },
  data () {
    return {
      settings,
      search: '',
      filteredA: -1
    }
  },
  computed: {
    prospects: function () {
      return this.$store.getters.get_prospects()
    },
    prospectList: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.prospects
      return this.prospects.filter(u => u.list === fil)
    },
    prospectSearch: function () {
      if (this.search) {
        return this.prospectList.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v) ||
            item.firstname.toLowerCase().includes(v) ||
            item.lastname.toLowerCase().includes(v) ||
            item.email.toLowerCase().includes(v) ||
            item.contact.city.toLowerCase().includes(v)
          )
        })
      }
      return this.prospectList
    }
  }
}
</script>
