<template>
  <div class="progress" v-if="lots.length">
    <div class="progress-bar" :style="width(0)">{{ quantity[0] }}</div>
    <div class="progress-bar" :style="width(1)">{{ quantity[1] }}</div>
    <div class="progress-bar" :style="width(2)">{{ quantity[2] }}</div>
    <div class="progress-bar" :style="width(3)">{{ quantity[3] }}</div>
    <div class="progress-bar" :style="width(4)">{{ quantity[4] }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProgrammesDonut',
  props: {
    numero: Number
  },
  data () {
    return {
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    quantity: function () {
      var quantity = []
      for (let i = 0; i < 5; i++) { quantity.push(this.lots.filter(l => Number(l.status) === i).length) }
      return quantity
    },
    percent: function () {
      var percent = []
      this.quantity.forEach(q => { percent.push(parseFloat((q / this.lots.length) * 100).toFixed(1)) })
      return percent
    }
  },
  methods: {
    width: function (val) {
      return 'width: ' + this.percent[val] + '%;'
    }
  }
}
</script>
