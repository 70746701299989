<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1 v-if="tab === 0"><i class="icon-reservation"></i>RÉSERVATIONS SYNTHÈSE</h1>
      <h1 v-if="tab === 1"><i class="icon-reservation"></i>RÉSERVATIONS SUIVI</h1>
      <h1 v-if="tab === 2"><i class="icon-alert"></i>DEMANDES EN ATTENTE</h1>
    </section>
    <section class="tabs">
      <h1 :class="{'active' : tab === 0}" @click="switchTab(0)"><i class="icon-list"></i>SYNTHÈSE</h1>
      <h1 :class="{'active' : tab === 1}" @click="switchTab(1)"><i class="icon-reservation"></i>SUIVI</h1>
      <h1 :class="{'active' : tab === 2}" @click="switchTab(2)"><i class="icon-alert"></i>DEMANDES</h1>
    </section>
    <section v-if="tab <2" class="reservations filter">
      <ul>
        <li class="filterchoice" :class="{'active' : filteredA > 0}">
          <select v-model="filteredA" @change="handleNumero(filteredA)">
            <option value="-1">Programmes: Tous</option>
            <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Status: Tous</option>
            <option value="-2">Non Annulé</option>
            <option v-for="(status, s) in settings.reservation.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li>
          <router-link v-if="!exp" @click="exp = true" tag="button" to="/reservations/expired"><i class="icon-alert" title="Expirée"></i></router-link>
          <router-link v-else @click="exp = false"  tag="button" to="/reservations"><i class="icon-reservation" title="Toute"></i></router-link>
          </li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="searchB" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link tag="li" to="/reservation/new" class="filteradd"><i class="icon-add"></i> Ajouter une réservation</router-link>
      </ul>
    </section>

    <SyntheseList v-if="tab === 0" :syntheses="reservSearch" />

    <ReservationsList v-if="tab === 1" :reservations="reservSearch" />

    <section v-if="tab === 2" class="demande filter">
      <ul>
        <li class="filterchoice" :class="{'active' : filteredC > 0}">
          <select v-model="filteredC" @change="handleNumero(filteredC)">
            <option value="-1">Programmes: Tous</option>
            <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredD">
            <option value="-1">Status: Tous</option>
            <option v-for="(status, st) in settings.request.status" :value="st" :key="st">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="searchA" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link tag="li" to="/demande/new" class="filteradd"><i class="icon-add"></i> Ajouter une demande</router-link>
      </ul>
    </section>

    <RequestList v-if="tab === 2" :requests="requestSearch" />

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ReservationsList from '@/components/ReservationsList.vue'
import RequestList from '@/components/RequestList.vue'
import SyntheseList from '@/components/SyntheseList.vue'
import settings from '@/settings.js'

export default {
  name: 'Reservations',
  components: {
    Profile,
    ReservationsList,
    RequestList,
    SyntheseList
  },
  data () {
    return {
      settings,
      searchA: '',
      searchB: '',
      exp: false,
      reservations: [],
      filteredA: -1,
      filteredB: -1,
      filteredC: -1,
      filteredD: 0
    }
  },
  watch: {
    $route: function (to, from) {
      // check for route change from expired to classic.
      this.reservations = this.all
      this.exp = false
      if (to.name === 'ReservationsExpired') {
        this.reservations = this.expired
        this.exp = true
      }
    }
  },
  created () {
    this.filteredA = this.numero
    this.filteredC = this.numero
    // show reservations expired
    this.reservations = this.all
    if (this.$router.currentRoute.name === 'ReservationsExpired') {
      this.reservations = this.expired
      this.exp = true
    }
  },
  computed: {
    numero: function () {
      return this.$store.getters.meta.menu.numero
    },
    tab: function () {
      return this.$store.getters.meta.menu.reservation
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    all: function () {
      return this.$store.getters.get_reservations()
    },
    expired: function () {
      return this.$store.getters.get_reservations_by_expired()
    },
    reservProgram: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.reservations
      return this.reservations.filter(el => el.program.numero === fil)
    },
    reservStatus: function () {
      var fil = Number(this.filteredB)
      // all status
      if (fil === -1) return this.reservProgram
      // all except cancel reservv
      if (fil === -2) return this.reservProgram.filter(el => el.status > 0)
      // filtered status
      return this.reservProgram.filter(el => el.status === fil)
    },
    reservSearch: function () {
      if (this.searchB) {
        return this.reservStatus.filter((item) => {
          return this.searchB.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v) ||
            item.program.shortname.toLowerCase().includes(v) ||
            item.user.firstname.toLowerCase().includes(v) ||
            item.user.lastname.toLowerCase().includes(v) ||
            item.prospect.firstname.toLowerCase().includes(v) ||
            item.prospect.lastname.toLowerCase().includes(v) ||
            item.notary.firstname.toLowerCase().includes(v) ||
            item.notary.lastname.toLowerCase().includes(v)
          )
        })
      }
      return this.reservStatus
    },
    requests: function () {
      return this.$store.getters.get_requests()
    },
    requestProgram: function () {
      var fil = Number(this.filteredC)
      if (fil === -1) return this.requests
      return this.requests.filter(el => el.program.numero === fil)
    },
    requestStatus: function () {
      var fil = Number(this.filteredD)
      if (fil === -1) return this.requestProgram
      return this.requestProgram.filter(el => el.status === fil)
    },
    requestSearch: function () {
      if (this.searchA) {
        return this.requestStatus.filter((item) => {
          return this.searchA.toLowerCase().split(' ').every(v =>
            item.lot.numero.toString().includes(v) ||
            item.program.shortname.toLowerCase().includes(v) ||
            item.user.firstname.toLowerCase().includes(v) ||
            item.user.lastname.toLowerCase().includes(v) ||
            item.prospect.firstname.toLowerCase().includes(v) ||
            item.prospect.lastname.toLowerCase().includes(v)
          )
        })
      }
      return this.requestStatus
    }
  },
  methods: {
    switchTab: function (value) {
      this.$store.commit('reservations_tab', value)
    },
    handleNumero: function (value) {
      this.$store.commit('numero_program', value)
      this.filteredA = value
      this.filteredC = value
    }
  }
}
</script>
