
// import DATABASE from '../database/_index.js'
import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchLots ({ commit, state }) {
    return new Promise((resolve, reject) => {
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchLots',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('set_lots', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Lots` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  addLotWithUpload ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var handlePayload = {
        lotNumero: payload.lot.uid,
        programNumero: payload.lot.program.numero,
        upload: payload.upload
      }
      if (payload.upload.length > 0) {
        // manager uploads
        this.dispatch('uploadLot', handlePayload)
          .then((filename) => {
            // create upload path
            var path = payload.lot.program.numero + '/' + payload.lot.uid + '/' + filename
            payload.lot.download = path
            // upload success
            this.dispatch('addLot', payload.lot)
              .then(() => {
                resolve()
              })
              .catch()
          })
          .catch()
      } else {
        this.dispatch('addLot', payload.lot)
          .then(() => {
            resolve()
          })
          .catch()
      }
    })
  },
  addLot ({ commit, state }, lot) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set lot parameters
      lot.id = this.getters.get_uuid()
      lot.created = Date.now()
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createLot',
          params: {
            id: lot.id,
            uid: lot.uid,
            numero: lot.numero,
            program: JSON.stringify(lot.program),
            spec: JSON.stringify(lot.spec),
            law: lot.law,
            lawyears: lot.lawyears,
            status: lot.status,
            reservation: JSON.stringify(lot.reservation),
            created: lot.created,
            download: lot.download,
            stats: JSON.stringify(lot.stats)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_lot', lot)
            var mess = 'Lot added to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to add lot in database!'
            commit('status_error', err)
          }
        })
    })
  },
  addLotImport ({ commit, state }, lot) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // set lot parameters
      lot.id = this.getters.get_uuid()
      lot.created = Date.now()
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'createLot',
          params: {
            id: lot.id,
            uid: lot.uid,
            numero: lot.numero,
            program: JSON.stringify(lot.program),
            spec: JSON.stringify(lot.spec),
            law: lot.law,
            lawyears: lot.lawyears,
            status: lot.status,
            reservation: JSON.stringify(lot.reservation),
            created: lot.created,
            download: lot.download,
            stats: JSON.stringify(lot.stats)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('create_lot', lot)
            var mess = 'Lot added to database!'
            commit('status_success', mess)
            resolve(true)
          } else {
            // error
            var err = 'Failed to add lot in database!'
            commit('status_error', err)
            resolve(false)
          }
        })
    })
  },
  editLotWithUpload ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var handlePayload = {
        lotNumero: payload.lot.uid,
        programNumero: payload.lot.program.numero,
        upload: payload.upload
      }
      if (payload.upload.length > 0) {
        // manager uploads
        this.dispatch('uploadLot', handlePayload)
          .then((filename) => {
            // create upload path
            var path = payload.lot.program.numero + '/' + payload.lot.uid + '/' + filename
            payload.lot.download = path
            // upload success
            this.dispatch('editLot', payload.lot)
              .then(() => {
                resolve()
              })
              .catch()
          })
          .catch()
      } else {
        this.dispatch('editLot', payload.lot)
          .then(() => {
            resolve()
          })
          .catch()
      }
    })
  },
  editLot ({ commit, state }, lot) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateLot',
          params: {
            id: lot.id,
            uid: lot.uid,
            numero: lot.numero,
            program: JSON.stringify(lot.program),
            spec: JSON.stringify(lot.spec),
            law: lot.law,
            lawyears: lot.lawyears,
            status: lot.status,
            reservation: JSON.stringify(lot.reservation),
            created: lot.created,
            download: lot.download,
            stats: JSON.stringify(lot.stats)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_lot', lot)
            var mess = 'Lot edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit lot in database!'
            commit('status_error', err)
          }
        })
    })
  },
  removeLot ({ commit, state }, lot) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'deleteLot',
          params: {
            id: lot.id
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('delete_lot', lot)
            var mess = 'Lot removed from database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to delete lot in database!'
            commit('status_error', err)
          }
        })
    })
  },
  requestLot ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      const USER = state.user
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'mailLot',
          params: {
            user: USER.username,
            program: payload.program.numero,
            lot: payload.numero,
            tel: USER.meta.contact.phone
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            var mess = 'Request has been sent'
            commit('status_success', mess)
            resolve(true)
          } else {
            // error
            var err = 'Request failed!'
            commit('status_error', err)
            resolve(false)
          }
        })
    })
  }
}

export default Actions
