<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>ÉDITION PROGRAMME</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Dénomination <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="num">Numéro (auto)</label>
            <input type="text" size="4" id="num" v-model="program.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="name">Nom du programme</label>
            <input type="text" id="name" v-model="program.name" />
          </p>
        </section>
        <section>
          <p>
            <label for="short">Nom court (10 char)</label>
            <input type="text" id="short" size="10" maxlength="10" v-model="program.shortname" />
          </p>
        </section>
        <section>
          <p>
            <label for="summary">Résumé (175 char)</label>
            <textarea maxlength="175" v-model="program.summary" id="summary"
            rows="5" cols="30"></textarea>
          </p>
        </section>
        <section>
          <p>
            <label for="desc">Description complète (500 char)</label>
            <textarea maxlength="400" v-model="program.description" id="desc"
            rows="12" cols="30"></textarea>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label for="stat">Statut</label>
            <select id="stat" v-model="program.status">
              <option v-for="(stat, s) in settings.program.status" :value="s" :key="s">{{ stat }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="category">Catégorie</label>
            <select id="category" v-model="program.category">
              <option value="0">Aucun</option>
              <option value="1">Résidence</option>
              <option value="2">villa</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="law">Dispositif de loi</label>
            <select id="law" v-model="program.law">
              <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="exp">Exposition</label>
            <select id="exp" v-model="program.exposure">
              <option v-for="(expo, e) in settings.lot.exposure" :value="e" :key="e">{{ expo }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="launch">Date de lancement</label>
            <v-date-picker id="launch" v-model="program.launch"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="dat">Achèvement des Travaux (dat)</label>
            <v-date-picker id="dat" v-model="program.dat"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="delivery">Date de livraison (année)</label>
            <v-date-picker id="delivery" v-model="program.delivery"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="program.created | shortDate" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Situation</h2>
        <section>
          <p>
            <label for="city">Ville</label>
            <input type="text" id="city" v-model="program.location.city" />
          </p>
        </section>
        <section>
          <p>
            <label for="adress">Adresse</label>
            <input type="text" id="adress" v-model="program.location.address" />
          </p>
        </section>
        <section>
          <p>
            <label for="district">Quartier</label>
            <input type="text" id="district" v-model="program.location.district" />
          </p>
        </section>
        <section>
          <p>
            <select v-model="program.location.region">
              <option value="-1">Aucune</option>
              <option v-for="(region, r) in settings.program.region" :value="r" :key="r">{{ region }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="country">Pays</label>
            <input type="text" id="country" v-model="program.location.country" />
          </p>
        </section>
        <section>
          <p>
            <label for="zip">Code postal</label>
            <input type="text" id="zip" v-model="program.location.zipcode" />
          </p>
        </section>
        <section>
          <p>
            <label for="position">Position</label>
            lat: <input type="text" id="position" v-model="program.location.position.lat" />
          </p>
          <p>
            lng: <input type="text" v-model="program.location.position.lng" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Types</h2>
        <section>
          <p>
            <label for="Tmin">Type Min</label>
            <select id="Tmin" v-model="program.lot.type.min">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="Tmax">Type Max</label>
            <select id="Tmax" v-model="program.lot.type.max">
              <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="quantity">Quantité des lots</label>
            <input type="number" id="quantity" v-model="program.lot.quantity" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Surfaces</h2>
        <section>
          <p>
            <label for="Smin">Taille Min</label>
            <input type="number" id="Smin" v-model="program.lot.size.min" step="0.01" min="0"/>
          </p>
        </section>
        <section>
          <p>
            <label for="Smax">Taille Max</label>
            <input type="number" id="Smax" v-model="program.lot.size.max" step="0.01" min="0" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Prix</h2>
        <section>
          <p>
            <label for="Pmin">Prix Min</label>
            <input type="number" id="Pmin" v-model="program.lot.price.min" />
          </p>
        </section>
        <section>
          <p>
            <label for="Pmax">Prix Max</label>
            <input type="number" id="Pmax" v-model="program.lot.price.max" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Images</h2>
        <section>
          <p>
            <label for="thumb">Vignette</label>
            <small>400x225 &lt; 200Ko</small>
            <input v-if="program.thumbnail === ''" type="file" id="thumb" ref="thumb" v-on:change="handle('thumb','thumb','img')"/>
            <em class="verysmall" v-else>{{ program.thumbnail }} <u class="icon-delete" v-on:click="program.thumbnail = ''"></u></em>
          </p>
        </section>
        <section>
          <p>
            <label for="logo">Logo</label>
            <small>128x128 &lt; 100Ko</small>
            <input v-if="program.logo === ''" type="file" id="logo" ref="logo" v-on:change="handle('logo','logo','img')"/>
            <em class="verysmall" v-else>{{ program.logo }} <u class="icon-delete" v-on:click="program.logo = ''"></u></em>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Galerie d'images</h2>
        <section>
          <p>
            <small>800x450 &lt; 400Ko</small>
            <em class="verysmall" >Image 1</em> <input v-if="check(0)" type="file" id="img1" ref="img1" v-on:change="handle('img1','img1','img')"/>
            <em class="verysmall" v-else>: {{ program.images[0] }} <u class="icon-delete" v-on:click="reset(0)"></u></em>
          </p>
          <p>
            <em class="verysmall">Image 2</em> <input v-if="check(1)" type="file" id="img2" ref="img2" v-on:change="handle('img2','img2','img')"/>
            <em class="verysmall" v-else>: {{ program.images[1] }} <u class="icon-delete" v-on:click="reset(1)"></u></em>
          </p>
          <p>
            <em class="verysmall">Image 3</em> <input v-if="check(2)" type="file" id="img3" ref="img3" v-on:change="handle('img3','img3','img')"/>
            <em class="verysmall" v-else>: {{ program.images[2] }} <u class="icon-delete" v-on:click="reset(2)"></u></em>
          </p>
          <p>
            <em class="verysmall">Image 4</em> <input v-if="check(3)" type="file" id="img4" ref="img4" v-on:change="handle('img4','img4','img')"/>
            <em class="verysmall" v-else>: {{ program.images[3] }} <u class="icon-delete" v-on:click="reset(3)"></u></em>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Téléchargement</h2>
        <section>
          <p>
            <label for="booklet">Plaquette commerciale</label>
            <small>.pdf &lt; 5Mo</small>
            <input v-if="program.download.booklet === ''" type="file" id="booklet" ref="booklet" v-on:change="handle('booklet','plaquette','doc')"/>
            <em class="verysmall" v-else>{{ program.download.booklet }} <u class="icon-delete" v-on:click="program.download.booklet = ''"></u></em>
          </p>
        </section>
        <section>
          <p>
            <label for="grid">Grille de prix</label>
            <small>.csv .xls .xlsx &lt; 5Mo</small>
            <input v-if="program.download.grid === ''" type="file" id="grid" ref="grid" v-on:change="handle('grid','grille','doc')"/>
            <em class="verysmall" v-else>{{ program.download.grid }} <u class="icon-delete" v-on:click="program.download.grid = ''"></u></em>
          </p>
        </section>
        <section>
          <p>
            <label for="simulator">Simulateur</label>
            <small>.xls .xlsx &lt; 5Mo</small>
            <input v-if="program.download.simulator === ''" type="file" id="simulator" ref="simulator" v-on:change="handle('simulator','simulateur','doc')"/>
            <em class="verysmall" v-else>{{ program.download.simulator }} <u class="icon-delete" v-on:click="program.download.simulator = ''"></u></em>
          </p>
        </section>
        <section>
          <p>
            <label for="contract">Contrat de partenariat</label>
            <small>.doc .docx &lt; 5Mo</small>
            <input v-if="program.download.contract === ''" type="file" id="contract" ref="contract" v-on:change="handle('contract','contrat','doc')"/>
            <em class="verysmall" v-else>{{ program.download.contract }} <u class="icon-delete" v-on:click="program.download.contract = ''"></u></em>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Videos</h2>
        <section>
          <p>
            <small>URL webinar (https://url.com/video)</small>
            <input type="text" id="video" size="30" v-model="program.download.video" />
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Statistiques</h2>
        <section>
          <p>
            <label for="clics">Nombre de clics</label>
            <input type="number" id="clics" v-model="program.stats.click" />
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ program }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit">EDITER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import settings from '@/settings.js'

export default {
  name: 'ProgrammeEdit',
  components: {
    Profile,
    InvalidForm
  },
  data () {
    return {
      settings,
      uploads: [],
      program: {}
    }
  },
  computed: {
    prog: function () {
      return this.$store.getters.get_program_by_numero(this.$route.params.id)
    }
  },
  created () {
    this.program = this.prog
  },
  methods: {
    edit: function () {
      const payload = {
        program: this.program,
        uploads: this.uploads
      }
      this.$store.dispatch('editProgramWithUploads', payload)
        .then(() => {
          this.$router.push('/programmes')
        })
        .catch()
    },
    handle: function (input, name, type) {
      var file = this.$refs[input].files[0]
      if (file) {
        var newupload = []
        newupload[0] = file
        newupload[1] = name
        newupload[2] = type
        newupload[3] = input
        this.uploads.push(newupload)
      }
    },
    reset: function (value) {
      this.program.images.splice(value, 1, '')
    },
    check: function (value) {
      if (this.program.images[value] === '') return true
      return false
    },
    validate: function () {
      if (!Number(this.program.numero)) return false
      if (this.program.name === '') return false
      if (this.program.shortname === '') return false
      return true
    }
  }
}
</script>
