<template>
  <section class="grid">
    <section class="card">
      <h2>{{program.name}}</h2>
      <table class="mailing" cellpadding="0" cellspacing="0">
        <tr align="center">
          <td class="bold">N°</td>
          <td class="bold">BAT</td>
          <td class="bold">TYP</td>
          <td class="bold">NIV</td>
          <td class="bold">SURF</td>
          <td class="bold">PRIX</td>
          <td class="bold">PART</td>
          <td class="bold">STAT</td>
        </tr>
        <tr align="center" v-for="(lot, l) in lots" :key="l" >
          <td>{{ lot.numero }}</td>
          <td>{{ lot.spec.building }}</td>
          <td>{{ lot.spec.type | type }}</td>
          <td>{{ lot.spec.floor | floor }}</td>
          <td>{{ lot.spec.size | square }}</td>
          <td>{{ lot.spec.price.toLocaleString() }}€</td>
          <td v-if="lot.status === 0">Aucun</td>
          <td v-else>{{ partner(lot.uid) }}</td>
          <td class="status" :class="status(lot.status)">
            {{ lot.status | lot_status }}
          </td>
        </tr>
      </table>
    </section>
  </section>
</template>

<script>
export default {
  name: 'MailingsTable',
  props: {
    program: Object
  },
  data () {
    return {
    }
  },
  computed: {
    lots: function () {
      const lots = this.$store.getters.get_lots_by_program_num(this.program.numero)
      return lots.slice().sort(function (a, b) {
        return (a.uid > b.uid) ? 1 : -1
      })
    },
    reservations: function () {
      return this.$store.getters.get_reservation_by_program_num(this.program.numero)
    },
    users: function () {
      return this.$store.getters.get_users()
    }
  },
  methods: {
    status: function (value) {
      return 'st' + value
    },
    partner: function (uid) {
      // find all reservation with uid
      const fil = this.reservations.filter(r => r.lot.uid === uid)
      // skip the cancel reservation
      const part = fil.find(r => r.status > 0)
      if (part) {
        var partner = this.users.find(el => el.username === part.user.username)
        if (partner.meta.lastname !== '') return partner.meta.lastname
        return partner.username
      }
      return ''
    }
  }
}
</script>
