<template>
  <section class="progs table">
    <ul>
      <li>
        <label class="sort" :class="classActive('numero')" @click="sortby('numero')">NUM</label>
        <label class="sort" :class="classActive('name')" @click="sortby('name')">NOM</label>
        <label>VILLE</label>
        <label>QUART</label>
        <label>PRIX</label>
        <label class="sort" :class="classActive('law')" @click="sortby('law')">LOI</label>
        <label>STATUS</label>
        <label>ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="prog in sorted" :key="prog.id" v-on:dblclick="router(prog.numero)">
        <span>{{ prog.numero }}</span>
        <span>{{ prog.shortname }}</span>
        <span>{{ prog.location.city || 'n/a' | uppercase }}</span>
        <span>{{ prog.location.district || 'n/a' }}</span>
        <span>{{ prog.lot.price.min.toLocaleString() }} €</span>
        <span>{{ prog.law | law }}</span>
        <span class="badge" :class="status(prog.status)">{{ prog.status | prog_status_short }}</span>
        <span>
          <router-link tag="i" :to="{ path: '/programme/details/'+prog.numero }" class="icon-see"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/programme/edit/'+prog.numero }" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/programme/del/'+prog.numero }" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="progs.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
  </section>
</template>

<script>

export default {
  name: 'ProgrammesList',
  props: {
    progs: Array
  },
  data () {
    return {
      dir: false,
      sort: 'numero'
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      return this.progs.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      })
    }
  },
  methods: {
    status: function (value) {
      return 'pr' + value
    },
    sortby: function (value) {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sort = 'numero'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (num) {
      this.$router.push('/programme/details/' + num)
    }
  }
}
</script>
