<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>DÉTAILS PROGRAMME</h1>
    </section>
    <section class="grid program">
      <section class="card grid">
          <section class="gallery">
            <section class="city">{{ program.location.city | uppercase }}</section>
            <span class="status">{{ program.status | prog_status }}</span>
            <section class="images">
              <img alt="" v-for="(img, i) in program.images" :class="{'active': gallery === i}" :key="i" :src="image(img)" />
              <ul>
                <li @click="gallery = 0" :class="{'active': gallery === 0}">*</li>
                <li @click="gallery = 1" :class="{'active': gallery === 1}">*</li>
                <li @click="gallery = 2" :class="{'active': gallery === 2}">*</li>
                <li @click="gallery = 3" :class="{'active': gallery === 3}">*</li>
              </ul>
            </section>
          </section>
          <section class="infos">
            <small v-if="isAdmin">
              <u class="icotext" @click="exportCSV()"><i class="">🗎</i>Export</u>&nbsp;
              <router-link class="icotext" :to="{ path: '/mailing/'+program.numero}"><i class="icon-mailing"></i>Mailing</router-link>&nbsp;
              <router-link tag="i" :to="{ path: '/programme/edit/'+program.numero}" class="icon-edit"></router-link>&nbsp;
              <router-link tag="i" :to="{ path: '/programme/del/'+program.numero}" class="icon-delete"></router-link>
            </small>
            <span class="title">{{ program.name }}</span>
            <span class="location"><i class="icon-location"></i> À {{ program.location.city }}, {{ program.location.district }}</span>
            <p>{{ program.description }}</p>
            <p v-if="program.download.video !== ''">
              <a title="Visionner la video webinar" target="_blank" :href="program.download.video">Présentation de la résidence</a>
            </p>
            <section class="inside">
              <ul class="badged">
                <li><p class="yellow">Dipositif <span>{{ program.law | law }}</span></p></li>
                <li><p class="blue">Du <span>{{ program.lot.type.min | type }} au {{ program.lot.type.max | type }}</span></p></li>
                <li><p class="">Livraison <span>{{ program.delivery | yearDate }}</span></p></li>
                <li><p class="grey">De <span>{{ program.lot.size.min }}m² à {{ program.lot.size.max }}m²</span></p></li>
                <li><p class="yellow">À partir de <span>{{ program.lot.price.min.toLocaleString() }} €</span></p></li>
                <li><p class="blue">DAT <span>{{ program.dat | yearDate }}</span></p></li>
              </ul>
              <section>
                <ul class="download">
                  <li v-if="program.download.booklet !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.booklet)">
                      <i class="bold">🕮</i><span>Plaquette</span>
                    </a>
                  </li>
                  <li v-if="program.download.grid !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.grid)">
                      <i class="bold">€</i><span>Grille de prix</span>
                    </a>
                  </li>
                  <li v-if="program.location.position.lat !== '' && program.location.position.lng !== ''">
                    <a :href="'https://www.google.fr/maps/dir/'
                     + program.location.position.lat + ','
                     + program.location.position.lng + '/'" target="_blank">
                      <i class="icon-location"></i><span>Plans</span>
                    </a>
                  </li>
                  <li v-if="program.download.simulator !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.simulator)">
                      <i class="bold">🗎</i><span>Simulateur</span>
                    </a>
                  </li>
                  <li v-if="program.download.contract !== ''">
                    <a href="javascript:void(0);" @click="down(program.download.contract)">
                      <i class="bold">🗎</i><span>contrat part</span>
                    </a>
                  </li>
                </ul>
                <Donut v-if="isAdmin" class="stats" v-bind:numero="program.numero" />
              </section>
            </section>
          </section>
      </section>
    </section>
    <section class="title">
      <h1><i class="icon-lot"></i>LES LOTS</h1>
    </section>
    <section class="lots filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredA">
            <option value="-1">Status: Tous</option>
            <option value="0">Disponible</option>
            <option v-if="isAdmin" value="1">Optionné</option>
            <option v-if="isAdmin" value="2">Réservé</option>
            <option v-if="isAdmin" value="3">Financé</option>
            <option v-if="isAdmin" value="4">Acté</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Type: Tous</option>
            <option v-for="(type, t) in settings.lot.type" :value="t" :key="t">{{ type }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredC">
            <option value="-1">Prix: Tous</option>
            <option v-for="(range, r) in settings.lot.ranges" :value="r" :key="r">{{ range.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredD">
            <option value="-1">Defisc: Toutes</option>
            <option v-for="(def, d) in settings.lot.defisc" :value="d" :key="d">{{ def.text }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <router-link v-if="isAdmin" tag="li" :to="{ path: '/lot/new/program/' + program.numero }" class="filteradd"><i class="icon-add"></i> Ajouter un lot</router-link>
      </ul>
    </section>

    <LotsList v-if="isAdmin" :lots="lotDefisc" />

    <LotsListLimited v-else-if="!closed" :lots="lotDefisc" />

  </section>
</template>

<script>
import settings from '@/settings.js'
import Profile from '@/components/Profile.vue'
import Donut from '@/components/ProgrammesDonut.vue'
import LotsList from '@/components/LotsList.vue'
import LotsListLimited from '@/components/LotsListLimited.vue'
import { image } from '@/helpers.js'

export default {
  name: 'ProgrammeDetail',
  components: {
    Profile,
    Donut,
    LotsList,
    LotsListLimited
  },
  data () {
    return {
      settings,
      gallery: 0,
      filteredA: -1,
      filteredB: -1,
      filteredC: -1,
      filteredD: -1
    }
  },
  computed: {
    program: function () {
      return this.$store.getters.get_program_by_numero(this.$route.params.id)
    },
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.$route.params.id)
    },
    lotStatus: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.lots
      return this.lots.filter(el => el.status === fil)
    },
    lotType: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.lotStatus
      return this.lotStatus.filter(el => el.spec.type === fil)
    },
    lotRange: function () {
      var fil = Number(this.filteredC)
      var range = this.settings.lot.ranges
      if (fil === -1) return this.lotType
      return this.lotType.filter(
        el => el.spec.price <= range[fil].max && el.spec.price >= range[fil].min)
    },
    lotDefisc: function () {
      var fil = Number(this.filteredD)
      var range = this.settings.lot.defisc
      if (fil === -1) return this.lotRange
      return this.lotRange.filter(
        el => el.spec.defisc <= range[fil].max && el.spec.price >= range[fil].min)
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    },
    closed: function () {
      if (this.program.status === 1) return false
      return true
    }
  },
  created () {
    // if program doesnt exist
    if (this.program === undefined) this.$router.push('/liste-des-programmes')
    // update stats when partner click on program
    if (!this.isAdmin) {
      // if program status is 'closed'
      if (this.closed) this.$router.push('/liste-des-programmes')
      var id = this.program.id
      this.$store.dispatch('editStatProgram', id)
        .then(() => {})
        .catch()
    }
  },
  methods: {
    image,
    down: function (url) {
      var href = this.settings.upload + url
      window.open(href, '_blank')
    },
    exportCSV: function () {
      var data = []
      this.lots.forEach(l => {
        // init var
        var associate = false
        var row = {
          lot: l.numero,
          prix: l.spec.price,
          programme: this.program.numero,
          denomination: this.program.shortname,
          reservation: '',
          date: '',
          status: '',
          client: '',
          nom: '',
          prenom: '',
          email: '',
          adresse: '',
          ville: '',
          postal: '',
          tel: ''
        }
        var reserv = this.$store.getters.get_reservations_by_lot_uid(l.uid).filter(r => r.status > 0)
        if (reserv.length > 0) {
          reserv = reserv.shift()
          row.reservation = reserv.numero
          row.date = this.$options.filters.shortDate(reserv.lot.datereserv)
          row.status = this.$options.filters.reservation_status(reserv.status)
          var prospect = this.$store.getters.get_prospect_by_numero(reserv.prospect.numero)
          row.client = prospect.numero
          row.nom = prospect.lastname
          row.prenom = prospect.firstname
          row.email = prospect.email
          row.adresse = prospect.contact.address
          row.ville = prospect.contact.city
          row.postal = prospect.contact.zipcode
          row.tel = this.$options.filters.phone(prospect.contact.phone)
          // if associate
          if (reserv.prospect.numero2) {
            associate = true
            var prospect2 = this.$store.getters.get_prospect_by_numero(reserv.prospect.numero2)
            var row2 = {
              lot: '',
              prix: '',
              programme: '',
              denomination: '',
              reservation: '',
              date: '',
              status: '',
              client: prospect2.numero,
              nom: prospect2.lastname,
              prenom: prospect2.firstname,
              email: prospect2.email,
              adresse: prospect2.contact.address,
              ville: prospect2.contact.city,
              postal: prospect2.contact.zipcode,
              tel: row.tel = this.$options.filters.phone(prospect2.contact.phone)
            }
          }
        }
        data.push(row)
        if (associate) data.push(row2)
      })
      this.createFile(data)
    },
    createFile: function (data) {
      // If data is not an object then JSON.parse will parse the JSON string in an Object
      var arrData = typeof data !== 'object' ? JSON.parse(data) : data
      var CSV = 'sep=,' + '\r\n'
      var label = ''
      for (var indexLabel in arrData[0]) {
        label += indexLabel + ','
      }
      label = label.slice(0, -1)
      CSV += label + '\r\n'
      for (var i = 0; i < arrData.length; i++) {
        var row = ''
        for (var index in arrData[i]) {
          row += '"' + arrData[i][index] + '",'
        }
        row.slice(0, row.length - 1)
        CSV += row + '\r\n'
      }
      // Initialize file format you want csv or xls
      const a = document.createElement('a')
      a.href = 'data:text/csvcharset=utf-8,' + escape(CSV)
      a.style = 'visibility:hidden'
      a.download = 'Export-Clients-' + this.program.shortname + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
