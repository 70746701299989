<template>
  <section class="accountReservations table">
    <ul>
      <li>
        <label title="Programme">PROG</label>
        <label title="Numéro du lot">LOT</label>
        <label title="Téléchargement des Documents">DOC</label>
        <label title="Partenaire">PART</label>
        <label title="Client">CLI</label>
        <label title="Status">STAT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(reserv, r) in sorted" :key="r" v-on:dblclick="router(reserv.numero)" >
        <span class="bold">
          <router-link :to="{ path: '/programme/details/' + reserv.program.numero }">
            {{ short(reserv.program.numero) || reserv.program.numero | capitalize }}
          </router-link>
        </span>
        <span>
          {{ reserv.lot.numero }}
        </span>
        <span>
          <i v-if="downloadLink(reserv.lot.uid) === 'x'">x</i>
          <u v-else @click="down(reserv.lot.uid)" title="Télécharger les documents">Docs</u>
        </span>
        <span >
          {{ reserv.user.lastname || 'n/a' }} {{ reserv.user.firstname | initial }}
        </span>
        <span>
          {{ reserv.prospect.lastname || 'n/a'  }} {{ reserv.prospect.firstname | initial }}&nbsp;
          <i :title="getName(reserv.prospect.numero2)" v-if="reserv.prospect.numero2">²</i>
        </span>
        <span class="badge" :class="status(reserv.status)">
          {{ reserv.status | reservation_status }}
          <sup class="" :class="{'expired' : dayLeft(reserv.created) < 0,
           'expired' : dayLeft(reserv.created) === 'exp'}"
            v-if="reserv.status === 1">{{ dayLeft(reserv.created) }}</sup>
        </span>
      </li>
    </ul>
    <p v-if="reservations.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < reservations.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
import settings from '@/settings.js'

export default {
  name: 'reservationsList',
  props: {
    reservations: Array
  },
  data () {
    return {
      settings,
      dir: false,
      sort: 'created',
      limit: 5
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      return this.reservations.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      }).slice(0, this.limit)
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lots: function () {
      return this.$store.getters.get_lots()
    },
    prospects: function () {
      return this.$store.getters.get_prospects()
    }
  },
  methods: {
    short: function (value) {
      var program = this.programs.find(p => Number(p.numero) === Number(value))
      return program.shortname
    },
    status: function (value) {
      return 'st' + value
    },
    sortby: function (value) {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sort = 'created'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    dayLeft: function (date) {
      const today = Date.now()
      const diff = today - date
      var days = 7 - (diff / (1000 * 3600 * 24))
      if (days < -15) return 'exp'
      return parseFloat(days).toFixed(0) + ' jr'
    },
    more: function () {
      this.limit += 5
    },
    date: function (value) {
      if (value === 0) return 'n/a'
      var shortDate = new Date(value)
      var options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return shortDate.toLocaleDateString('fr-FR', options)
    },
    getName: function (prosp) {
      var prospect = this.prospects.find(el => el.numero === prosp)
      var lastname = prospect.lastname || 'n/a'
      var firstname = prospect.firstname || 'n/a'
      return lastname + ' ' + firstname
    },
    downloadLink: function (id) {
      var lot = this.lots.find(el => el.uid === id)
      if (lot.download) return this.settings.upload + lot.download
      return 'x'
    },
    down: function (id) {
      const a = document.createElement('a')
      var lot = this.lots.find(el => el.uid === id)
      a.href = this.settings.upload + lot.download
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      this.$store.dispatch('editStatLot', lot.id)
        .then(() => {})
        .catch()
    }
  }
}
</script>
