import Utilisateurs from '@/views/Users/Users.vue'
import UtilisateurDetails from '@/views/Users/User_Details.vue'
import UtilisateurNew from '@/views/Users/User_New.vue'
import UtilisateurEdit from '@/views/Users/User_Edit.vue'
import UtilisateurDel from '@/views/Users/User_Del.vue'

const users = [
  {
    path: '/utilisateurs',
    name: 'Utilisateurs',
    component: Utilisateurs,
    meta: { lvl: 3 }
  },
  {
    path: '/utilisateur/details/:id',
    name: 'UtilisateurDetails',
    component: UtilisateurDetails,
    meta: { lvl: 3 }
  },
  {
    path: '/utilisateur/new',
    name: 'UtilisateurNew',
    component: UtilisateurNew,
    meta: { lvl: 4 }
  },
  {
    path: '/utilisateur/edit/:id',
    name: 'UtilisateurEdit',
    component: UtilisateurEdit,
    meta: { lvl: 4 }
  },
  {
    path: '/utilisateur/del/:id',
    name: 'UtilisateurDel',
    component: UtilisateurDel,
    meta: { lvl: 5 }
  }
]

export default users
