
const Mutations = {
  // Default
  reset_state (state) {
    state = {
      status: {
        code: 0,
        logs: [],
        message: ''
      },
      user: { meta: {} },
      auth: JSON.parse(localStorage.getItem('auth')) || { key: '', token: '', exp: 0 },
      db: {
        programs: [],
        lots: [],
        requests: [],
        reservations: [],
        prospects: [],
        users: [],
        config: []
      }
    }
  },
  set_cookies (state) {
    var cookies = true
    state.cookies = cookies
    localStorage.setItem('cookies', JSON.stringify(cookies))
  },
  reset_cookies (state) {
    var cookies = false
    state.cookies = cookies
    localStorage.setItem('cookies', JSON.stringify(cookies))
  },
  // Status
  status_standby (state) {
    state.status.code = 0
    state.status.message = ''
  },
  status_loading (state) {
    state.status.code = 1
    state.status.message = '↻'
  },
  status_success (state, mess) {
    state.status.code = 2
    state.status.logs.push(mess)
    state.status.message = mess
  },
  status_error (state, mess) {
    state.status.code = 3
    state.status.logs.push(mess)
    state.status.message = mess
  },
  // Programs
  set_programs (state, payload) {
    state.db.programs = payload
  },
  create_program (state, payload) {
    state.db.programs.push(payload)
  },
  update_program (state, payload) {
    const index = state.db.programs.map(o => o.id).indexOf(payload.id)
    state.db.programs.splice(index, 1, payload)
  },
  delete_program (state, payload) {
    const index = state.db.programs.map(o => o.id).indexOf(payload.id)
    state.db.programs.splice(index, 1)
  },
  // Lots
  set_lots (state, payload) {
    state.db.lots = payload
  },
  create_lot (state, payload) {
    state.db.lots.push(payload)
  },
  update_lot (state, payload) {
    const index = state.db.lots.map(o => o.id).indexOf(payload.id)
    state.db.lots.splice(index, 1, payload)
  },
  delete_lot (state, payload) {
    const index = state.db.lots.map(o => o.id).indexOf(payload.id)
    state.db.lots.splice(index, 1)
  },
  // Requests
  set_requests (state, payload) {
    state.db.requests = payload
  },
  create_request (state, payload) {
    state.db.requests.push(payload)
  },
  update_request (state, payload) {
    const index = state.db.requests.map(o => o.id).indexOf(payload.id)
    state.db.requests.splice(index, 1, payload)
  },
  delete_request (state, payload) {
    const index = state.db.requests.map(o => o.id).indexOf(payload.id)
    state.db.requests.splice(index, 1)
  },
  // Reservations
  set_reservations (state, payload) {
    state.db.reservations = payload
  },
  create_reservation (state, payload) {
    state.db.reservations.push(payload)
  },
  update_reservation (state, payload) {
    const index = state.db.reservations.map(o => o.id).indexOf(payload.id)
    state.db.reservations.splice(index, 1, payload)
  },
  delete_reservation (state, payload) {
    const index = state.db.reservations.map(o => o.id).indexOf(payload.id)
    state.db.reservations.splice(index, 1)
  },
  // Prospects
  set_prospects (state, payload) {
    state.db.prospects = payload
  },
  create_prospect (state, payload) {
    state.db.prospects.push(payload)
  },
  update_prospect (state, payload) {
    const index = state.db.prospects.map(o => o.id).indexOf(payload.id)
    state.db.prospects.splice(index, 1, payload)
  },
  delete_prospect (state, payload) {
    const index = state.db.prospects.map(o => o.id).indexOf(payload.id)
    state.db.prospects.splice(index, 1)
  },
  // Users
  set_user (state, payload) {
    state.user = payload
  },
  set_users (state, payload) {
    state.db.users = payload
  },
  create_user (state, payload) {
    state.db.users.push(payload)
  },
  update_user (state, payload) {
    const index = state.db.users.map(o => o.id).indexOf(payload.id)
    state.db.users.splice(index, 1, payload)
  },
  delete_user (state, payload) {
    const index = state.db.users.map(o => o.id).indexOf(payload.id)
    state.db.users.splice(index, 1)
  },
  // Auth
  login (state, payload) {
    state.auth = payload
  },
  logout (state) {
    state.status.code = 0
    state.status.message = ''
    state.auth = { key: '', token: '', exp: 0 }
    state.user = { meta: {} }
  },
  // Config
  set_config (state, payload) {
    state.db.config = payload
  },
  update_config (state, payload) {
    const index = state.db.config.map(o => o.id).indexOf(payload.id)
    state.db.config.splice(index, 1, payload)
  },
  // Interface preference
  numero_program (state, payload) {
    state.user.meta.menu.numero = payload
  },
  program_list (state, payload) {
    state.user.meta.menu.program = payload
  },
  reservations_tab (state, payload) {
    state.user.meta.menu.reservation = payload
  }
  /*
  color_switch (state, payload) {
    state.user.meta.menu.color = payload
  },
  display_switch (state, payload) {
    state.user.meta.menu.display = payload
  }
  */
}

export default Mutations
