<template>
  <section class="container">
    <section class="inside">
      <router-link tag="section" :to="{ path: '/programme/details/'+prog.numero }" class="image">
        <img alt="" :src="image(prog.thumbnail)" />
      </router-link>
    </section>
    <section class="inside">
      <span class="title">{{ prog.name }}</span>
      <span class="location"><i class="icon-location"></i> À {{ prog.location.city }}, {{ prog.location.district }}</span>
      <p>{{ prog.summary.slice(0, 75) }}...</p>
    </section>
    <section class="inside infos">
      <span class="city">{{ prog.location.city | uppercase }}</span>
      <span class="law">Dispositif {{ prog.law | law }}</span>
      <span class="status">{{ prog.status | prog_status }}</span>
    </section>
    <section class="inside detail">
      <ul>
        <li>DU<span>{{ prog.lot.type.min | type }} au {{ prog.lot.type.max | type }}</span></li>
        <li>DE<span>{{ parseInt(prog.lot.size.min) }}m² à {{ parseInt(prog.lot.size.max) }}m²</span></li>
        <li>À PARTIR DE<span>{{ prog.lot.price.min.toLocaleString() }} €</span></li>
        <li>LIVRAISON<span>{{ prog.delivery | yearDate }}</span></li>
      </ul>
    </section>
    <Donut class="inside" v-bind:numero="prog.numero" />
    <section class="inside edit">
      <small>
        <ul>
          <li><router-link tag="i" :to="{ path: '/programme/details/'+prog.numero }" class="icon-see"></router-link></li>
          <li><router-link tag="i" :to="{ path: '/programme/edit/'+prog.numero }" class="icon-edit"></router-link></li>
          <li><router-link tag="i" :to="{ path: '/programme/del/'+prog.numero }" class="icon-delete"></router-link></li>
        </ul>
      </small>
    </section>
  </section>
</template>

<script>
import Donut from '@/components/ProgrammesDonut.vue'
import { image } from '@/helpers.js'

export default {
  name: 'ProgrammesSmallCard',
  components: {
    Donut
  },
  props: {
    prog: Object
  },
  data () {
    return {
    }
  },
  methods: {
    image
  }
}
</script>
