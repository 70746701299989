<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-lot"></i>DÉTAILS LOT</h1>
    </section>
    <section class="grid">
      <section class="card">
        <h2>Programme</h2>
        <small class="corner right">
            <router-link tag="span" class="icotext" :to="{ path: '/lot/duplicate/'+lot.uid}"><i class="icon-dupli"></i>Dupliquer</router-link>&nbsp;
            <router-link tag="i" :to="{ path: '/lot/edit/'+lot.uid}" class="icon-edit"></router-link>&nbsp;
            <router-link tag="i" :to="{ path: '/lot/del/'+lot.uid}" class="icon-delete"></router-link>
          </small>
        <section class="new">
          <figure>
            <img alt="" :src="image(program.thumbnail)" />
          </figure>
        </section>
        <section>
          <p>
            <label class="badge">Programme:</label> {{ program.name }}
          </p>
          <p>
            <label class="badge">Statut:</label> {{ program.status | prog_status }}
          </p>
          <p>
            <label class="badge">Ville:</label> {{ program.location.city }} - {{ program.location.district }}
          </p>
        </section>
      </section>
    </section>
    <section class="grid add">
      <section class="card">
        <h2>Lot</h2>
        <section>
          <p>
            <label class="badge">Lot numéro:</label> {{ lot.numero }} ({{ lot.uid }})
          </p>
          <p>
            <label class="badge">Type:</label> {{ lot.spec.type | type }}
          </p>
          <p>
            <label class="badge">Étage:</label> {{ lot.spec.floor | floor }}
          </p>
          <p>
            <label class="badge">Batiment:</label> {{ lot.spec.building }}
          </p>
          <p>
            <label class="badge">Exposition:</label> {{ lot.spec.exposure | exposure }}
          </p>
          <p>
            <label class="badge">Téléchargement:</label> {{ lot.download }} (zip)
          </p>
        </section>
      </section>
      <section class="card">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label class="badge">Prix:</label> {{ lot.spec.price }} €
          </p>
          <p>
            <label class="badge">Loyer:</label> {{ lot.spec.loan }} €
          </p>
          <p>
            <label class="badge">Frais de notaire:</label> {{ lot.spec.notaryfees }} €
          </p>
          <p>
            <label class="badge">Prix parking:</label> {{ lot.spec.parkingprice }} €
          </p>
          <p>
            <label class="badge">Statut:</label> <span class="badge" :class="status(lot.status)">{{ lot.status | lot_status }}</span>
          </p>
          <p>
            <label class="badge">Dispositif de loi:</label> {{ lot.law | law }}
          </p>
          <p>
            <label class="badge">Année de défiscalisation:</label> {{ lot.lawyears | lawyears }}
          </p>
        </section>
      </section>
      <section class="card">
        <h2>Options</h2>
        <section>
          <p>
            <label class="badge">Surface:</label> {{ lot.spec.size }} m²
          </p>
          <p>
            <label class="badge">Balcon :</label> {{ lot.spec.balcony | square }}
          </p>
          <p>
            <label class="badge">Varangue:</label> {{ lot.spec.varangue }} m²
          </p>
          <p>
            <label class="badge">Terrasse:</label> {{ lot.spec.terrace }} m²
          </p>
          <p>
            <label class="badge">Total:</label> {{ lot.spec.total }} m²
          </p>
          <p>
            <label class="badge">Jardin:</label> {{ lot.spec.garden | square }}
          </p>
          <p>
            <label class="badge">Cave:</label> {{ lot.spec.cellar || 'non' }}
          </p>
          <p>
            <label class="badge">Parking:</label> {{ lot.spec.parking || 'non' }}
          </p>
        </section>
      </section>
    </section>
    <section class="grid">
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ lot }}</pre>
      </section>
    </section>
    <section class="title">
      <h1><i class="icon-reservation"></i>DETAILS DES RESERVATIONS</h1>
    </section>
    <ReservationsList :reservations="reservations" />
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ReservationsList from '@/components/ReservationsList.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'lotEdit',
  components: {
    Profile,
    ReservationsList
  },
  data () {
    return {
      settings,
      types: 8
    }
  },
  computed: {
    lot: function () {
      return this.$store.getters.get_lot_by_uid(this.$route.params.id)
    },
    program: function () {
      return this.$store.getters.get_program_by_numero(this.lot.program.numero)
    },
    reservations: function () {
      return this.$store.getters.get_reservations_by_lot_uid(this.$route.params.id)
    }
  },
  created () {
  },
  methods: {
    status (value) {
      return 'st' + value
    },
    image
  }
}
</script>
