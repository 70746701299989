<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-settings">⚙</i>PARAMÈTRES</h1>
    </section>
    <form @submit.prevent="save()">
      <section class="grid add">
        <section class="card new">
          <h2>Administrateur <sup class="textred">*</sup></h2>
          <p>
            <label for="email">Adresse principale</label>
            <input type="text" id="email" v-model="config.settings.email" required />
          </p>
          <p>
            <label for="emailbackup">Adresse secondaire</label>
            <input type="text" id="emailbackup" v-model="config.settings.emailbackup" />
          </p>
        </section>
        <section class="card new">
          <h2>Mise en avant</h2>
          <section>
            <p>
              <label for="num">Programme 1</label>
              <select id="program" v-model="config.settings.highlight[0]">
                <option value="0">Aucun</option>
                <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="num">Programme 2</label>
              <select id="program" v-model="config.settings.highlight[1]">
                <option value="0">Aucun</option>
                <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
            <p>
              <label for="num">Programme 3</label>
              <select id="program" v-model="config.settings.highlight[2]">
                <option value="0">Aucun</option>
                <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
              </select>
              <i class="icon-select"></i>
            </p>
          </section>
        </section>
        <section class="card new">
          <h2>Accueil partenaires</h2>
          <p>
            <label for="intro">Message d'accueil (400 char)</label>
            <textarea maxlength="400" v-model="config.settings.intro" id="intro"
            rows="5" cols="30"></textarea>
          </p>
        </section>
        <section class="card new">
          <h2>Fermeture</h2>
          <section>
            <p>
              <label for="access">Activé</label>
              <select id="access" v-model="config.settings.closed">
                <option :value="0">non</option>
                <option :value="1">oui</option>
              </select>
              <i class="icon-select"></i>
            </p>
          </section>
        </section>
        <section class="card new">
          <h2>Bannir</h2>
          <section>
            <p>
              <label for="num">Adresse IP</label>
              <ul>
                <li class="badge" v-for="(ban,b) in config.bans" :key="b">
                  <span>{{ ban }}</span> <u class="verysmall icon-delete" @click="remove(b)"></u>
                </li>
              </ul>
              <i class="icon-select"></i>
              <input type="text" id="ip" v-model="ip" /> <em class="btn icon-add" @click="add()"></em>
            </p>
          </section>
        </section>
        <section class="card new">
          <h2>Téléchargement</h2>
          <section class="scroll">
            <p>
              <label>Plaquette commerciale</label>
              <small>.pdf &lt; 5Mo</small>
            </p>
            <p>
              <em class="verysmall">Titre 1: </em>
              <input type="text" id="name1" v-model="config.downloads.name1" /><br/>
              <em class="verysmall">Fichier 1: </em>
              <input type="file" v-if="config.downloads.url1 === ''" id="url1" ref="url1" v-on:change="handle('url1','doc1','doc')"/>
              <em class="verysmall" v-else>{{ config.downloads.url1 }} <u class="icon-delete" v-on:click="config.downloads.url1 = ''"></u></em>
            </p>
           <hr/>
           <p>
              <em class="verysmall">Titre 2: </em>
              <input type="text" id="name2" v-model="config.downloads.name2" /><br/>
              <em class="verysmall">Fichier 2: </em>
              <input type="file" v-if="config.downloads.url2 === ''" id="url2" ref="url2" v-on:change="handle('url2','doc2','doc')"/>
              <em class="verysmall" v-else>{{ config.downloads.url2 }} <u class="icon-delete" v-on:click="config.downloads.url2 = ''"></u></em>
            </p>
            <hr/>
            <p>
              <em class="verysmall">Titre 3: </em>
              <input type="text" id="name3" v-model="config.downloads.name3" /><br/>
              <em class="verysmall">Fichier 3: </em>
              <input type="file" v-if="config.downloads.url3 === ''" id="url3" ref="url3" v-on:change="handle('url3','doc3','doc')"/>
              <em class="verysmall" v-else>{{ config.downloads.url3 }} <u class="icon-delete" v-on:click="config.downloads.url3 = ''"></u></em>
            </p>
            <hr/>
            <p>
              <em class="verysmall">Titre 4: </em>
              <input type="text" id="name4" v-model="config.downloads.name4" /><br/>
              <em class="verysmall">Fichier 4: </em>
              <input type="file" v-if="config.downloads.url4 === ''" id="url4" ref="url4" v-on:change="handle('url4','doc4','doc')"/>
              <em class="verysmall" v-else>{{ config.downloads.url4 }} <u class="icon-delete" v-on:click="config.downloads.url4 = ''"></u></em>
            </p>
            <hr/>
            <p>
              <em class="verysmall">Titre 5: </em>
              <input type="text" id="name5" v-model="config.downloads.name5" /><br/>
              <em class="verysmall">Fichier 5: </em>
              <input type="file" v-if="config.downloads.url5 === ''" id="url5" ref="url5" v-on:change="handle('url5','doc5','doc')"/>
              <em class="verysmall" v-else>{{ config.downloads.url5 }} <u class="icon-delete" v-on:click="config.downloads.url5 = ''"></u></em>
            </p>
          </section>
        </section>
        <section class="card new"><small>Data.json</small>
          <pre class="scroll">{{ config }}</pre>
        </section>
      </section>
      <section class="card">
      <p>
        <button type="submit">ENREGISTRER</button>
      </p>
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'

export default {
  name: 'Statistiques',
  components: {
    Profile
  },
  data () {
    return {
      config: {},
      uploads: [],
      ip: ''
    }
  },
  computed: {
    programs: function () {
      return this.$store.getters.get_programs()
    },
    conf: function () {
      return this.$store.getters.get_config()
    }
  },
  created () {
    this.config = JSON.parse(JSON.stringify(this.conf))
  },
  methods: {
    save: function () {
      const payload = {
        config: this.config,
        uploads: this.uploads
      }
      this.$store.dispatch('editConfigWithUploads', payload)
        .then(() => {
          this.$router.push('/')
        })
        .catch()
    },
    handle: function (input, name, type) {
      var file = this.$refs[input].files[0]
      if (file) {
        var newupload = []
        newupload[0] = file
        newupload[1] = name
        newupload[2] = type
        newupload[3] = input
        this.uploads.push(newupload)
      }
    },
    remove: function (value) {
      this.config.bans.splice(value, 1)
    },
    add: function () {
      this.config.bans.push(this.ip)
    }
  }
}
</script>
