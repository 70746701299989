<template>
  <aside class="menu" :class="{'drop': drop}">
    <nav>
      <router-link tag="img" to="/" :src="settings.upload + 'logo.png'" alt=""></router-link>
      <nav class="burger" @click="dropMenu()">☰</nav>
      <ul v-if="intern" @click="drop = 0">
        <router-link tag="li" to="/"><i class="icon-dashboard"></i> DASHBOARD</router-link>
        <router-link class="li-dark" tag="li" to="/programmes"><i class="icon-programme"></i> PROGRAMMES</router-link>
        <router-link tag="li" to="/lots"><i class="icon-lot"></i> LOTS</router-link>
        <router-link class="li-dark" tag="li" to="/reservations"><i class="icon-reservation"></i> RÉSERVATIONS</router-link>
        <router-link tag="li" to="/utilisateurs"><i class="icon-utilisateur"></i> UTILISATEURS</router-link>
        <router-link class="li-dark" tag="li" to="/prospects"><i class="icon-prospect"></i> PROSPECTS</router-link>
        <router-link tag="li" to="/mailings"><i class="icon-mailing"></i> MAILINGS</router-link>
        <router-link v-if="admin" class="li-dark" tag="li" to="/statistiques"><i class="icon-statistique"></i> STATISTIQUES</router-link>
        <router-link v-if="superadmin" tag="li" to="/parametres"><i class="icon-parametres">⚙</i> PARAMÈTRES</router-link>
      </ul>
      <ul v-else @click="drop = 0">
        <router-link class="li-dark" tag="li" to="/"><i class="icon-dashboard"></i> ACCUEIL</router-link>
        <router-link tag="li" to="/liste-des-programmes"><i class="icon-programme"></i> PROGRAMMES</router-link>
      </ul>
      <ul class="logout">
        <router-link tag="li" to="/logout"><i class="icon-deconnexion"></i> DÉCONNEXION</router-link>
      </ul>
      <code>Version {{ settings.version }}</code>
    </nav>
  </aside>
</template>

<script>
import settings from '@/settings.js'

export default {
  name: 'Menu',
  data () {
    return {
      settings,
      drop: 0
    }
  },
  computed: {
    lvl: function () {
      return this.$store.getters.lvl
    },
    intern: function () {
      return this.$store.getters.lvl > 2
    },
    admin: function () {
      return this.$store.getters.lvl > 3
    },
    superadmin: function () {
      return this.$store.getters.lvl > 4
    }
  },
  methods: {
    dropMenu: function () {
      this.drop = (this.drop ? 0 : 1)
    }
  }
}
</script>
