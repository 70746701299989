<template>
  <main id="app" :class="{'login': login}">
    <loginmess v-if="login"/>
    <sidemenu v-else/>
    <router-view v-if="load" />
    <loading v-else :messages="messages" />
    <errors/>
  </main>
</template>

<script>
import Errors from '@/components/Errors.vue'
import Menu from '@/components/Menu.vue'
import LoginMessage from '@/components/LoginMessage.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'App',
  components: {
    sidemenu: Menu,
    errors: Errors,
    loginmess: LoginMessage,
    loading: Loading
  },
  data () {
    return {
      database: 0,
      databaseCount: 7,
      load: false,
      fetch: false,
      messages: {
        code: '200',
        message: ''
      }
    }
  },
  computed: {
    login: function () {
      return (this.$route.name === 'Login' ? 1 : 0)
    },
    isLogged: function () {
      return this.$store.getters.isLoggedIn
    },
    lvl: function () {
      return this.$store.getters.lvl
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    }
  },
  watch: {
    $route: function (to, from) {
      // check for route change from /login to /.
      if (from.name === 'Login' && !this.fetch) {
        // fetching with profile update
        this.fetchingData(true)
      }
      // synchronisation database
      switch (to.name) {
        case 'Logout':
          // reset state if logout
          this.$store.commit('reset_state')
          this.database = 0
          this.fetch = false
          break
        case 'Utilisateurs':
          if (this.fetch) {
            this.$store.dispatch('fetchUsers')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Programmes':
          if (this.fetch) {
            this.$store.dispatch('fetchPrograms')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'ProgrammesLimited':
          if (this.fetch) {
            this.$store.dispatch('fetchPrograms')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Lots':
          if (this.fetch) {
            this.$store.dispatch('fetchLots')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Prospects':
          if (this.fetch) {
            this.$store.dispatch('fetchProspects')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
        case 'Reservations':
          if (this.fetch) {
            this.$store.dispatch('fetchReservations')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchRequests')
              .then(() => { })
              .catch((err) => { this.messages = err.status })
          }
          break
      }
      this.checkAccessLvl()
      // cant use isAdmin for 'some' reason (buffering data) but its the same checkout
      if (this.lvl < 5) this.checkClosed()
    },
    database: function (count) {
      // check if all database are this.fetch
      if (count === this.databaseCount) {
        this.checkAccessLvl()
        if (!this.isAdmin) this.checkClosed()
      }
    }
  },
  created () {
    // check for token in Web storage (DOM)
    if (!this.isLogged) {
      // no token
      this.load = true
    } else {
      // validate token
      this.$store.dispatch('validToken')
        .then(() => {
          // fecth data
          this.fetchingData()
        })
        .catch(() => {
          // token expired
          this.load = true
        })
    }
  },
  methods: {
    checkAccessLvl: function () {
      this.load = false
      const route = this.$router.currentRoute.name
      if (route === 'Login') {
        // access allow !exception rule for login
        this.load = true
        return
      }
      if (this.lvl) {
        if (this.$route.meta.lvl > this.lvl) {
          // access denied
          if (route !== 'Restricted') {
            this.$router.push('/restricted')
          }
        } else {
          // access granted
          this.load = true
        }
      }
    },
    checkClosed: function () {
      const route = this.$router.currentRoute.name
      // interface closed by admin
      var closed = this.$store.getters.get_closed()
      if (closed) {
        if (route !== 'Closed') {
          this.$router.push('/closed')
        }
      }
    },
    fetchingData: function (update = false) {
      // Fetch Users TABLE
      this.$store.dispatch('fetchUsers')
        .then(() => {
          // get profile
          this.$store.dispatch('fetchMyProfile')
            .then(() => {
              this.database++
              // set update from login
              const payload = {
                login: true,
                meta: {}
              }
              if (update) this.$store.dispatch('updateMyProfile', payload)
            })
          // Fetch Others DATABASE FILE after USER
          this.$store.dispatch('fetchConfig')
            .then(() => { this.database++ })
            .catch((err) => { this.messages = err.status })
          this.$store.dispatch('fetchPrograms')
            .then(() => { this.database++ })
            .catch((err) => { this.messages = err.status })
          this.$store.dispatch('fetchLots')
            .then(() => { this.database++ })
            .catch((err) => { this.messages = err.status })
          this.$store.dispatch('fetchReservations')
            .then(() => { this.database++ })
            .catch((err) => { this.messages = err.status })
          // Fetch ADMIN related DATABASE
          if (this.isAdmin) {
            this.$store.dispatch('fetchProspects')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
            this.$store.dispatch('fetchRequests')
              .then(() => { this.database++ })
              .catch((err) => { this.messages = err.status })
          } else {
            // Fake the DATABASE count
            this.database++
            this.database++
          }
          this.fetch = true
        })
        .catch((err) => { this.messages = err.status })
    }
  }
}
</script>
