<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-reservation"></i>ÉDITION RÉSERVATION</h1>
    </section>
    <form @submit.prevent="edit()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.thumbnail || '')" /></figure>
          <p>
            <span class="badge">Nom:</span> {{ program.name }}
          </p>
          <p>
            <span class="badge">Ville:</span> {{ program.location.city }}
          </p>
          <p>
            <span class="badge">Quartier:</span> {{ program.location.district }}
          </p>
          <p>
            <span class="badge">Livraison:</span> {{ program.dat | yearDate }}
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Lot <sup class="textred">*</sup></h2>
        <section v-if="!canceled">
          <p>
            <span class="badge">Numéro lot:</span> {{ lot.numero }} ({{ lot.uid }})
          </p>
          <p>
            <span class="badge">Infos:</span> {{ lot.spec.type | type }} ({{ lot.spec.floor | floor }}) {{ lot.spec.size || '0'  }} m² (Bat: {{ lot.spec.building }})
          </p>
          <p>
            <span class="badge">Prix:</span> {{ lot.spec.price.toLocaleString()  || '0' }} €
          </p>
          <p>
            <span class="badge">Statut:</span> <span class="badge" :class="status(lot.status)">{{ lot.status | lot_status }}</span>
          </p>
          <p>
            <label for="stat">Statut</label>
            <select id="stat" v-model="reservation.status">
              <option v-for="(reserv, r) in settings.reservation.status" :value="r" :key="r">{{ reserv }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <p>
            <label for="law">Dispositif de loi</label>
            <select id="law" v-model="reservation.lot.law">
              <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <p>
            <label for="law">Durée de défiscalisation</label>
            <select id="law" v-model="reservation.lot.lawyears">
              <option v-for="(years, y) in settings.lot.lawyears" :value="y" :key="y">{{ years }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
          <p>
            <label for="extra">Option supplémentaire (ex: parking)</label>
            <input type="text" id="extra" v-model="reservation.lot.extra" />
          </p>
          <p>
            <label for="actedprice">Prix Acté</label>
            <input type="number" step="0.01" id="actedprice" v-model="reservation.lot.actedprice" />
          </p>
        </section>
        <section v-else>
          <p>
            <span class="badge">Status:</span> <span class="badge" :class="status(0)">{{ 0 | reservation_status }}</span>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
         <section>
          <p>
            <label for="num">Numéro (auto)</label>
            <input type="text" id="num" v-model="reservation.numero" disabled />
          </p>
        </section>
        <section>
          <p>
            <label for="created">Date de création</label>
            <input type="text" id="created" disabled :value="reservation.created | shortDate" />
          </p>
        </section>
        <section>
          <label for="datereserv">Date de reservation</label>
          <v-date-picker id="datereserv" v-model="reservation.lot.datereserv"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </section>
      </section>
      <section class="card new">
        <h2>Partenaire <sup class="textred">*</sup></h2>

        <findUser :user="reservation.user.username" @send="setUser" />

      </section>
      <section class="card new">
        <h2>Client <sup class="textred">*</sup></h2>

        <findProspect :filter="1" :prospect="reservation.prospect.numero" :type="'prospect'" @send="setProspect" />

        <section v-if="reservation.prospect.numero">

          <hr/>

          <label class="small" for="associate">
            <input type="checkbox" class="toggle" id="associate" v-model="reservation.prospect.associate" />
            Ajout d'un conjoint, associé
          </label>

          <findProspect v-if="reservation.prospect.associate" :filter="1" :prospect="reservation.prospect.numero2"
           :type="'prospect'" @send="setAssociate" />

        </section>

        <hr/>
        <p>
          <label for="endorsement1">Avenant 1
            <input type="checkbox" class="toggle" id="endorsement1" v-model="reservation.prospect.endorsement1" />
          </label>
        </p>
        <p>
          <label for="endorsement2">Avenant 2
            <input type="checkbox" class="toggle" id="endorsement2" v-model="reservation.prospect.endorsement2" />
          </label>
        </p>
        <p>
          <label for="deposit">Caution (1000 €)
            <input type="checkbox" class="toggle" id="deposit" v-model="reservation.prospect.deposit" />
          </label>
        </p>
        <p>
          <label for="letter" @change="today('sru')">Lettre SRU
            <input type="checkbox" class="toggle" id="letter" v-model="reservation.prospect.letter" />
          </label>
        </p>
        <p v-if="reservation.prospect.letter">
          <label class="small" for="datesru">Date d'envoi SRU</label>
          <v-date-picker id="datesru" v-model="reservation.prospect.datesru"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </p>
        <p>
          <label for="acknowledgment">Accusé de reception
            <input type="checkbox" class="toggle" id="acknowledgment" v-model="reservation.prospect.acknowledgment" />
          </label>
        </p>
      </section>
      <section class="card new">
        <h2>Notaire</h2>

        <findProspect :filter="3" :prospect="reservation.notary.numero" :type="'notary'" @send="setProspect" />

        <section>
          <p>
            <label for="involvement">Implication</label>
            <select id="involvement" v-model="reservation.notary.involvement">
              <option :value="2">aucune</option>
              <option :value="0">participation</option>
              <option :value="1">procuration</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <hr/>
        <section>
          <p>
            <label for="send">Dossier envoyé
              <input type="checkbox" class="toggle" id="send" v-model="reservation.notary.send" />
            </label>
          </p>
          <p>
            <label for="sendoffer">Offre de prêt envoyé
              <input type="checkbox" class="toggle" id="sendoffer" v-model="reservation.notary.sendoffer" />
            </label>
          </p>
          <p>
            <label for="cash">Paiement cash
              <input type="checkbox" class="toggle" id="cash" v-model="reservation.notary.cash" />
            </label>
          </p>
          <p>
            <label for="not" @change="today('notif')">Notification
              <input type="checkbox" class="toggle" id="not" v-model="reservation.notary.notif" />
            </label>
          </p>
          <p v-if="reservation.notary.notif">
            <label class="small" for="notary.notifdate">Date notification</label>
            <v-date-picker id="notary.notifdate" v-model="reservation.notary.notifdate"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
          <p>
            <label for="notary.sign">Acte de vente
              <input type="checkbox" class="toggle" id="notary.sign" v-model="reservation.notary.sign" />
            </label>
          </p>
          <p v-if="reservation.notary.sign">
            <label class="small" for="notary.signdate">Date de l'acte</label>
            <v-date-picker id="notary.signdate" v-model="reservation.notary.signdate"
            :masks="{ input: 'DD/MM/YYYY' }"
            :model-config="{ type: 'number' }">
              <template #default="{ inputValue, inputEvents }">
                <input :value="inputValue" v-on="inputEvents" />
              </template>
            </v-date-picker>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Banque</h2>

        <findProspect :filter="4" :prospect="reservation.bank.numero" :type="'bank'" @send="setProspect" />

      </section>
      <section class="card new">
        <h2>Banquier</h2>
        <p>
          <label for="conselor.firstname">Nom / Prénom</label>
          <input type="text" id="conselor.firstname" v-model="reservation.bank.counselor.firstname" />
        </p>
        <p>
          <label for="conselor.email">Email</label>
          <input type="text" id="conselor.email" v-model="reservation.bank.counselor.email" />
        </p>
        <p>
          <label for="conselor.phone">Téléphone</label>
          <input type="text" id="conselor.phone" v-model="reservation.bank.counselor.phone" />
        </p>
      </section>
      <section class="card new">
        <h2>Emprunt</h2>
        <p>
          <label for="loan.agreement">Accord de prêt
            <input type="checkbox" class="toggle" id="loan.agreement" v-model="reservation.bank.loan.agreement" />
          </label>
        </p>
        <p v-if="reservation.bank.loan.agreement">
          <label class="small" for="loan.agreementdate">Date accord</label>
          <v-date-picker id="loan.agreementdate" v-model="reservation.bank.loan.agreementdate"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </p>
        <p>
          <label for="loan.offer">Offre de prêt
            <input type="checkbox" class="toggle" id="loan.offer" v-model="reservation.bank.loan.offer" />
          </label>
        </p>
        <p v-if="reservation.bank.loan.offer">
          <label class="small" for="loan.offerdate">Date offre</label>
          <v-date-picker id="loan.offerdate" v-model="reservation.bank.loan.offerdate"
          :masks="{ input: 'DD/MM/YYYY' }"
          :model-config="{ type: 'number' }">
            <template #default="{ inputValue, inputEvents }">
              <input :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </p>
        <p>
          <label for="loan.amount">Montant</label>
          <input type="number" id="loan.amount" v-model="reservation.bank.loan.amount" />
        </p>
        <p>
          <label for="loan.contribution">Apport</label>
          <input type="number" id="loan.contribution" v-model="reservation.bank.loan.contribution" />
        </p>
        <p>
          <label for="loan.duration">Durée (années)</label>
          <input type="number" id="loan.duration" v-model="reservation.bank.loan.duration" />
        </p>
        <p>
          <label for="loan.rate">Taux %</label>
          <input type="number" id="loan.rate" step="0.01" v-model="reservation.bank.loan.rate" />
        </p>
        <p>
          <label for="loan.guarantee">Garantie</label>
          <input type="text" id="loan.guarantee" v-model="reservation.bank.loan.guarantee" />
        </p>
      </section>
      <section class="card new">
        <h2>Remarques</h2>
        <section>
          <p>
            <label for="notarycost">Frais de notaire offert</label>
            <select id="notarycost" v-model="reservation.remarks.notarycost">
              <option :value="0">non</option>
              <option :value="1">oui</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="managementcost">Frais de gestion offert</label>
            <select id="managementcost" v-model="reservation.remarks.managementcost">
              <option :value="0">non</option>
              <option :value="1">oui</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
        <section>
          <p>
            <label for="remarktext">Texte</label>
            <textarea maxlength="400" v-model="reservation.remarks.text" id="remarktext"
            rows="10" cols="30"></textarea>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Api</h2>
        <section>
          <p>
            <label for="apisign">Docusign URL</label>
            <input type="text" id="apisign" v-model="reservation.api.sign" />
          </p>
        </section>
        <section>
          <p>
            <label for="ar24">Ar24 URL</label>
            <input type="text" id="ar24" v-model="reservation.api.ar24" />
          </p>
        </section>
      </section>
      <section class="card new"><small>Data.json</small>
        <pre class="scroll">{{ reservation }}</pre>
      </section>
    </section>
    <section v-if="validate()" class="card">
      <p>
        <button type="submit">ÉDITER</button>
      </p>
    </section>
    <InvalidForm v-else />
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import FindUser from '@/components/FindUser.vue'
import FindProspect from '@/components/FindProspect.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'ReservationEdit',
  components: {
    Profile,
    InvalidForm,
    FindUser,
    FindProspect
  },
  data () {
    return {
      settings,
      canceled: false,
      reservation: {}
    }
  },
  computed: {
    // setup
    reserv: function () {
      return this.$store.getters.get_reservation_by_numero(this.$route.params.id)
    },
    program: function () {
      return this.$store.getters.get_program_by_numero(this.reservation.program.numero)
    },
    lot: function () {
      return this.$store.getters.get_lot_by_uid(this.reservation.lot.uid)
    },
    // utilisateurs
    users: function () {
      return this.$store.getters.get_users()
    },
    // prospects
    prospects: function () {
      return this.$store.getters.get_prospects()
    }
  },
  created () {
    this.reservation = this.reserv
    // dont edit lot if already canceled
    if (this.reservation.status === 0) {
      this.canceled = true
    }
  },
  methods: {
    edit: function () {
      const payload = {
        reservation: this.reservation,
        cancel: this.canceled
      }
      this.$store.dispatch('editReservation', payload)
        .then(() => {
          this.$router.push('/reservations')
        })
        .catch()
    },
    setUser: function (user) {
      if (user) {
        var data = this.users.find(u => u.username === user)
        this.reservation.user.username = user
        this.reservation.user.id = data.id
        this.reservation.user.firstname = data.meta.firstname
        this.reservation.user.lastname = data.meta.lastname
      } else {
        this.reservation.user.id = ''
        this.reservation.user.username = ''
        this.reservation.user.firstname = ''
        this.reservation.user.lastname = ''
      }
    },
    setProspect: function (numero, type) {
      if (numero > 0) {
        var data = this.prospects.find(p => Number(p.numero) === numero)
        this.reservation[type].id = data.id
        this.reservation[type].numero = numero
        this.reservation[type].firstname = data.firstname
        this.reservation[type].lastname = data.lastname
        this.reservation[type].email = data.email
        this.reservation[type].phone = data.contact.phone
      } else {
        this.reservation[type].id = ''
        this.reservation[type].numero = 0
        this.reservation[type].firstname = ''
        this.reservation[type].lastname = ''
        this.reservation[type].email = ''
        this.reservation[type].phone = ''
      }
    },
    setAssociate: function (numero) {
      this.reservation.prospect.numero2 = 0
      if (numero > 0) {
        this.reservation.prospect.numero2 = numero
      }
    },
    status: function (value) {
      return 'st' + value
    },
    today: function (from) {
      if (from === 'sru' && this.reservation.prospect.letter) this.reservation.prospect.datesru = Date.now()
      else this.reservation.prospect.datesru = ''
      if (from === 'notif' && this.reservation.notary.notif) this.reservation.notary.notifdate = Date.now()
      else this.reservation.notary.notifdate = ''
    },
    validate: function () {
      if (this.reservation.user.username === '') return false
      if (!this.reservation.prospect.numero) return false
      return true
    },
    image
  }
}
</script>
