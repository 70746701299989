<template>
  <section class="demandes table">
    <ul>
      <li>
        <label class="sort reset" @click="reset()" title="Programme">PROG</label>
        <label title="Numéro du lot">LOT</label>
        <label class="sort" :class="classActive('created')" @click="sortby('created')">DATE</label>
        <label title="Dispositif de défiscalisation">LOI</label>
        <label title="Partenaire">PART</label>
        <label title="Client">CLI</label>
        <label title="Status">STAT</label>
        <label>ACT</label>
      </li>
    </ul>
    <ul>
      <li class="card td" v-for="(request, r) in sorted" :key="r" v-on:dblclick="router(request.numero, request.status)">
        <span class="bold">
          <router-link :to="{ path: '/programme/details/' + request.program.numero }">
          {{ short(request.program.numero) || request.program.numero | capitalize }}
          </router-link>
        </span>
        <span>{{ request.lot.numero }}</span>
        <span class="bold">{{ request.created | shortDate }}</span>
        <span class="bold">{{ request.lot.law | law }}
          <sup class="small" v-if="request.lot.lawyears"> {{ request.lot.lawyears | lawyears }}</sup>
        </span>
        <span :title="user(request.user.username).lastname + ' ' + user(request.user.username).firstname">
          <router-link v-if="request.user.username !== ''" :to="{ path: '/utilisateur/details/'+request.user.username }">
            {{ user(request.user.username).meta.lastname || 'n/a' }}
          </router-link>
        </span>
        <span :title="prospect(request.prospect.numero).lastname + ' ' + prospect(request.prospect.numero).firstname">
          <router-link v-if="request.prospect.numero" :to="{ path: '/prospect/details/'+request.prospect.numero }">
            {{ prospect(request.prospect.numero).lastname || 'n/a'  }}
          </router-link>
        </span>
        <span class="badge" :class="status(request.status)">
          {{ request.status | request_status }}
          <sup v-if="request.status < 1">{{ rank(request) }}</sup>
        </span>
        <span>
          <router-link v-if="request.status < 1" tag="i" :to="{ path: '/demande/edit/'+request.numero }" class="icon-edit"></router-link>&nbsp;
          <router-link tag="i" :to="{ path: '/demande/del/'+request.numero }" class="icon-delete"></router-link>
        </span>
      </li>
    </ul>
    <p v-if="requests.length === 0" class="card nodata">Aucune donnée n’est disponible</p>
    <p class="loadmore" v-if="limit < requests.length">
      <button @click="more()">Afficher plus</button>
    </p>
  </section>
</template>

<script>
export default {
  name: 'requestsList',
  props: {
    requests: Array
  },
  data () {
    return {
      dir: false,
      sort: 'created',
      limit: 50
    }
  },
  computed: {
    sorted: function () {
      var dir = this.dir
      var sort = this.sort
      return this.requests.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      }).slice(0, this.limit)
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    users: function () {
      return this.$store.getters.get_users()
    },
    prospects: function () {
      return this.$store.getters.get_prospects()
    }
  },
  methods: {
    short: function (value) {
      var program = this.programs.find(p => Number(p.numero) === Number(value))
      return program.shortname
    },
    status: function (value) {
      return 'rq' + value
    },
    sortby: function (value) {
      if (this.sort === value) {
        if (this.dir) this.dir = false
        else this.dir = true
      } else {
        this.sort = value
      }
    },
    reset: function () {
      this.sort = 'created'
      this.dir = true
    },
    classActive: function (value) {
      var classe = ''
      if (this.sort === value) classe = 'active'
      if (this.dir === false) classe += ' dir'
      return classe
    },
    router: function (numero, status) {
      if (!status) this.$router.push('/demande/edit/' + numero)
    },
    // rank standby request request by date
    rank: function (request) {
      var req = this.$store.getters.get_requests_standby_by_uid(request.lot.uid)
      var filter = req.slice().sort(function (a, b) {
        return (a.created > b.created) ? 1 : -1
      })
      return filter.indexOf(request) + 1
    },
    more: function () {
      this.limit += 50
    },
    user: function (user) {
      var u = this.users.find(el => el.username === user)
      if (u) return u
      // deleted from user database
      return {
        meta: {
          lastname: 'unknown',
          firstname: ''
        }
      }
    },
    prospect: function (prosp) {
      var p = this.prospects.find(el => el.numero === prosp)
      if (p) return p
      // deleted from user database
      return {
        lastname: 'unknown',
        firstname: ''
      }
    }
  }
}
</script>
