<template>
  <div class="content scroll">

    <div v-html="text"></div>

    <u @click="resetCookies()">reset cookies 🍪</u>

  </div>
</template>

<script>

export default {
  name: 'Privacy',
  data () {
    return {
      text: ''
    }
  },
  created () {
    this.getText()
  },
  methods: {
    getText: function () {
      this.$store.dispatch('privacy')
        .then((data) => {
          this.text = data
        })
        .catch()
    },
    resetCookies: function () {
      return this.$store.commit('reset_cookies')
    }
  }
}
</script>
