<template>
  <section class="table by4 scroll">
    <ul>
      <li><label>TYPE</label><label>NOMBRE</label><label>PRIX</label><label>P.C</label></li>
    </ul>
    <ul>
      <li v-for="(t) in types" :value="t" :key="t">
        <span v-if="lotsbytype[t-1].length"><i class="badge">{{ t-1 | type }}</i></span>
        <span v-if="lotsbytype[t-1].length">{{lotsbytype[t-1].length}}</span>
        <span v-if="lotsbytype[t-1].length">{{price[t-1]}} €</span>
        <span v-if="lotsbytype[t-1].length">{{ percent[t-1] }}%</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'StatisticsProgramType',
  props: {
    numero: Number
  },
  data () {
    return {
      types: 13
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    quantity: function () {
      var quantity = []
      for (let i = 0; i < this.types; i++) {
        quantity.push(this.lots.filter(l => Number(l.spec.type) === i).length)
      }
      return quantity
    },
    lotsbytype: function () {
      var lotsbytype = []
      for (let i = 0; i < this.types; i++) {
        lotsbytype.push(this.lots.filter(l => Number(l.spec.type) === i))
      }
      return lotsbytype
    },
    price: function () {
      var price = []
      this.lotsbytype.forEach(q => {
        if (q.length > 0) {
          var calc = 0
          q.forEach(l => {
            calc = calc + l.spec.price
          })
          price.push(calc.toLocaleString())
        } else price.push(0)
      })
      return price
    },
    percent: function () {
      var percent = []
      this.quantity.forEach(q => {
        percent.push(Number(((q / this.lots.length) * 100).toFixed(2)))
      })
      if (percent.length < 1) return ''
      return percent
    }
  }
}
</script>
