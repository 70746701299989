<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-lot"></i>IMPORTER LOTS</h1>
    </section>
    <form @submit.prevent="ImportLots()">
    <section class="grid add">
      <section class="card new">
        <h2>Programme <sup class="textred">*</sup></h2>
        <section>
          <figure><img alt="" :src="image(program.data.thumbnail || '')" /></figure>
          <p>
            <label for="num">Programme (Obligatoire)</label>
            <select id="program" v-model="program.numero" @click="setProgram()">
              <option value="0">Aucun</option>
              <option v-for="prog in programs" :value="prog.numero" :key="prog.id">{{ prog.name }}</option>
            </select>
            <i class="icon-select"></i>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Upload <sup class="textred">*</sup></h2>
        <section>
          <p>
            <label for="csv">Fichier excel</label>
            <small>.csv &lt; 1Mo</small>
            <input type="file" id="csv" ref="csv" accept=".csv" v-on:change="handle('csv')"/>
          </p>
          <p v-if="file.length > 0">
            <small><em class="bold">" {{ file.length }} "</em> ligne(s) dans le fichier</small>
            <button v-if="this.program.numero" type="button" class="btn" @click="setLots()">Créer la grille</button>
            <span v-else class="badge red small">Aucun programme sélectionné</span>
          </p>
        </section>
      </section>
      <section class="card new">
        <h2>Paramètres</h2>
        <section>
          <p>
            <label for="delayl">Delais d'envois</label>
            <small>Intervalle en millisecondes</small>
            <input type="text" id="delay" v-model="ms" />
          </p>
          <p>
            <label for="delayl">Téléchargement</label>
            <a :href="settings.upload + 'import.ex.csv'" target="_blank">
              Modèle excel csv
            </a>
          </p>
        </section>
      </section>
      <!--
      <section class="card new">
        <small>Data.json</small>
        <pre class="scroll">
          {{ lots }}
        </pre>
      </section>
      -->
    </section>
    <section class="card">
      <section class="lots table import">
        <ul>
          <li>
            <label title="Nom du programme">PROG</label>
            <label title="Lettre du bâtiment">BAT</label>
            <label title="Étage du lot">NIV</label>
            <label title="Numéro du lot">NUM</label>
            <label title="Type de lot">TYPE</label>
            <label title="Surface habitable du lot">SH</label>
            <label title="Avec balcon">BAL</label>
            <label title="Avec varangue">VAR</label>
            <label title="Avec terrasse">TER</label>
            <label title="Avec logia">LOG</label>
            <label title="Surface défiscalisable">SD</label>
            <label title="Avec jardin">JARD</label>
            <label title="Avec cave (numero box)">CAV</label>
            <label title="Avec parking (numero place)">PARK</label>
            <label title="Prix du parking">PP</label>
            <label title="Prix total">PRIX</label>
            <label title="Montant frais de notaire">NOT</label>
            <label title="Montant du loyer">LOY</label>
            <label title="Dispositif de défiscalisation">LOI</label>
            <label title="Prix défiscalisable">DEFISC</label>
            <label title="Exposition">EXP</label>
            <label title="Fichier & doc de vente">DOC</label>
            <label title="Status du lot">STAT</label>
            <label title="Numero du lot">UID</label>
            <label title="Edit">SUPP</label>
          </li>
        </ul>
        <ul v-if="lots.length > 0">
          <li class="card td" v-for="(row, r) in lots" :key="r">
            <span class="bold">{{ row.program.shortname }}</span>
            <span>
              <input v-model="row.spec.building" />
            </span>
            <span>
              <input v-model="row.spec.floor" />
            </span>
            <span class="small">{{ row.numero }}</span>
            <span>
              <select id="type" v-model="row.spec.type">
                <option v-for="(lot, lt) in settings.lot.type" :value="lt" :key="lt">{{ lot }}</option>
              </select> {{ row.spec.story | story }}
            </span>
            <span>
              <input v-model="row.spec.size" />
            </span>
            <span>
              <input v-model="row.spec.balcony" />
            </span>
            <span>
              <input v-model="row.spec.varangue" />
            </span>
            <span>
              <input v-model="row.spec.terrace" />
            </span>
            <span>
              <input v-model="row.spec.logia" />
            </span>
            <span>
              <input v-model="row.spec.total" />
            </span>
            <span>
              <input v-model="row.spec.garden" />
            </span>
            <span>
              <input v-model="row.spec.cellar" />
            </span>
            <span>
              <input v-model="row.spec.parking" />
            </span>
            <span>
              <input v-model="row.spec.parkingprice" />
            </span>
            <span>
              <input v-model="row.spec.price" />
            </span>
            <span>
              <input v-model="row.spec.notaryfees" />
            </span>
            <span>
              <input v-model="row.spec.loan" />
            </span>
            <span>
              <select id="law" v-model="row.law">
                <option v-for="(law, l) in settings.lot.law" :value="l" :key="l">{{ law }}</option>
              </select>
            </span>
            <span>
              <input v-model="row.spec.defisc" />
            </span>
            <span>
              <select id="exp" v-model="row.spec.exposure">
              <option v-for="(expo, e) in settings.lot.exp" :value="e" :key="e">{{ expo }}</option>
            </select>
            </span>
            <span><u :title="settings.upload + row.download">doc</u></span>
            <span class="badge st0">Disponible</span>
            <span> {{ row.uid }}</span>
            <span><i class="icon-delete" @click="del(r)"></i></span>
          </li>
        </ul>
      </section>
    </section>
    <hr/>
    <section class="grid">
      <section v-if="validate()" class="card">
        <small v-if="message.start">
          envoyé: {{ message.send }} / restant: {{ message.remaining }} <br/>
          lot: {{ message.uid }} <i v-if="message.status" class="round green">✔</i><i v-else class="round red">✘</i>
        </small>
        <p>
          <button type="submit">ENVOYER</button>
        </p>
      </section>
      <InvalidForm v-else />
    </section>
    </form>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import InvalidForm from '@/components/InvalidForm.vue'
import { image } from '@/helpers.js'
import settings from '@/settings.js'

export default {
  name: 'lotNew',
  components: {
    Profile,
    InvalidForm
  },
  data () {
    return {
      settings,
      message: {
        start: false,
        finish: false,
        uid: '',
        status: false,
        send: 0,
        remaining: 0
      },
      ms: 250,
      lots: [],
      file: [],
      program: {
        numero: 0,
        data: {}
      }
    }
  },
  computed: {
    numero: function () {
      return this.$store.getters.meta.menu.numero
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    lotsByProgram: function () {
      return this.$store.getters.get_lots_by_program_num(this.program.numero)
    }
  },
  created () {
  },
  methods: {
    setProgram: function () {
      if (Number(this.program.numero)) {
        this.program.data = this.programs.find(p => p.numero === this.program.numero)
      } else {
        this.program = {
          numero: 0,
          data: {
            thumbnail: ''
          }
        }
      }
    },
    setLots: function () {
      if (this.lots.length > 0) return false
      var scope = this
      this.file.forEach(l => {
        var build = l.batiment.toUpperCase()
        if (build === 'E') build = 'EE'
        var UIDD = scope.program.data.numero + build + (l.numero < 10 ? '0' : '') + l.numero
        var lot = {
          id: '',
          uid: UIDD,
          numero: l.numero,
          program: {
            id: scope.program.data.id,
            numero: scope.program.data.numero,
            shortname: scope.program.data.shortname
          },
          spec: {
            building: l.batiment,
            type: l.type,
            exposure: l.exposition,
            story: l.superposition,
            floor: l.etage,
            size: l.surface,
            cellar: l.cave,
            balcony: l.balcon,
            varangue: l.varangue,
            logia: l.logia,
            terrace: l.terrasse,
            garden: l.jardin,
            total: l.surfacedefisc,
            parking: l.parking,
            parkingprice: l.parkingprix,
            parkingloan: l.parkingloyer,
            price: l.prix,
            defisc: l.coutdefisc,
            loan: l.loyer,
            notaryfees: l.fraisnotaire
          },
          law: l.loi,
          lawyears: 0,
          status: 0,
          reservation: {
            numero: '',
            actedprice: 0,
            date: 0
          },
          created: Date.now(),
          download: scope.program.data.numero + '/' + UIDD + '/download.zip',
          stats: {
            click: 0
          }
        }
        scope.lots.push(lot)
      })
      return false
    },
    del: function (index) {
      console.log(this.lots[index])
      this.lots.splice(index, 1)
    },
    handle: function (input) {
      const file = this.$refs[input].files[0]
      const reader = new FileReader()
      var scope = this
      reader.onload = function (e) {
        const text = e.target.result
        scope.file = scope.csvToArray(text)
      }
      reader.readAsText(file)
    },
    csvToArray: function (str, delimiter = ';') {
      const headers = str.slice(0, str.indexOf('\r\n')).split(delimiter)
      const firstrows = str.slice(str.indexOf('\n') + 1).split('\r\n')
      const rows = []
      // clean file from empty end lines
      firstrows.forEach(row => {
        if (row !== '') rows.push(row)
      })
      const arr = rows.map(function (row) {
        const values = row.split(delimiter)
        const el = headers.reduce(function (object, header, index) {
          object[header] = values[index]
          return object
        }, {})
        return el
      })
      return arr
    },
    // start the import process
    ImportLots: function () {
      var list = this.lots
      this.message.remaining = list.length
      this.message.start = true
      this.send(list)
        .then(() => {
          // message has been sent
          this.finish = true
        })
        .catch()
    },
    // loop until list is finish
    send: async function (list) {
      for (var lots of list) {
        await this.sender(lots)
        await this.delay(this.ms)
      }
    },
    // delay between each loop
    delay: function (t) {
      return new Promise(resolve => setTimeout(resolve, t))
    },
    // send email
    sender: function (payload) {
      this.$store.dispatch('addLotImport', payload)
        .then((s) => {
          this.message.uid = payload.uid
          this.message.send++
          this.message.remaining--
          this.message.status = s
        })
        .catch()
    },
    validate: function () {
      if (!Number(this.program.numero)) return false
      if (Object.keys(this.file) < 1) return false
      if (Object.keys(this.lots) < 1) return false
      return true
    },
    image
  }
}
</script>
