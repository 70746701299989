<template>
  <section class="interface">
      <Profile/>
      <section class="title">
        <h1><i class="icon-dashboard"></i>DASHBOARD</h1>
      </section>
      <section class="content welcome">
        <em class="welcome">Bienvenue à vous <i v-if="meta.firstname">{{ meta.firstname }} {{ meta.lastname }} </i></em>
        <time :datetime="today"><i class="icon-calendar"></i> {{ today | longDate }}</time>
      </section>
    <div v-if="isAdmin">

      <DasboardStatsTotal />

      <DasboardStatsTraffic />

      <section class="title">
        <h1><i class="icon-alert"></i> ALERTES</h1>
      </section>

      <ReservationsList :reservations="expired" />

    </div>

    <DasboardPartner v-else />

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import DasboardStatsTotal from '@/components/DasboardStatsTotal.vue'
import DasboardStatsTraffic from '@/components/DasboardStatsTraffic.vue'
import DasboardPartner from '@/components/DasboardPartner.vue'
import ReservationsList from '@/components/ReservationsList.vue'

export default {
  name: 'Dashboard',
  components: {
    Profile,
    DasboardStatsTotal,
    DasboardStatsTraffic,
    DasboardPartner,
    ReservationsList
  },
  data () {
    return {
    }
  },
  computed: {
    meta: function () {
      return this.$store.getters.meta
    },
    today: function () {
      return Date.now()
    },
    expired: function () {
      return this.$store.getters.get_reservations_by_expired()
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin
    }
  },
  created () {
    this.menu()
    this.color()
    this.display()
  },
  methods: {
    // set size of side menu
    menu: function () {
      const main = document.querySelector('main')
      if (this.meta.menu.side) main.classList.add('menulock')
      else main.classList.remove('menulock')
    },
    // set table text density display
    display: function () {
      const body = document.body
      if (this.meta.menu.display) body.classList.add('compact')
      else body.classList.remove('compact')
    },
    // set original status color
    color: function () {
      const root = document.documentElement
      if (this.meta.menu.color) {
        root.style.setProperty('--reserved', '#d9b600')
        root.style.setProperty('--financed', '#0254a2')
        root.style.setProperty('--acted', '#01396f')
      } else {
        root.style.setProperty('--reserved', '#33d4e8')
        root.style.setProperty('--financed', '#6ddc76')
        root.style.setProperty('--acted', '#f34f7d')
      }
    }
  }
}
</script>
