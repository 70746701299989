<template>
  <section class="dashboard grid">

    <section class="card total">
      <small>Nombre total de programmes</small>
      <p>
        <em>{{ programs_count }}</em>
        <span class="blue"><i class="icon-programme"></i></span>
      </p>
    </section>

    <section class="card total">
      <small>Nombre total de lots</small>
      <p>
        <em>{{ lots_count }}</em>
        <span class="grey"><i class="icon-lot"></i></span>
      </p>
    </section>

    <section class="card total">
      <small>Nombre total de prospects</small>
      <p>
        <em>{{ prospects_count }}</em>
        <span class="yellow"><i class="icon-prospect"></i></span>
      </p>
    </section>

  </section>
</template>

<script>
export default {
  name: 'DashboardStatsTraffic',
  data () {
    return {
    }
  },
  computed: {
    programs_count: function () {
      var progs = this.$store.getters.get_programs()
      return Object.keys(progs).length - 1
    },
    lots_count: function () {
      var lots = this.$store.getters.get_lots()
      return Object.keys(lots).length
    },
    prospects_count: function () {
      var prospects = this.$store.getters.get_prospects()
      return Object.keys(prospects).length
    }
  }
}
</script>
