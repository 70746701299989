<template>
  <section class="table by3">
    <ul>
      <li><label>STAT</label><label>PRIX</label><label>P.C</label></li>
    </ul>
    <ul>
      <li>
        <span><i style="color:var(--grey)">⬤</i> Disponible</span>
        <span>{{ price[0] }} €</span>
        <span>{{ percent[0] }} %</span>
      </li>
      <li>
        <span><i style="color:var(--yellow)">⬤</i> Optionné</span>
        <span>{{ price[1] }} €</span>
        <span>{{ percent[1] }} %</span>
      </li>
      <li>
        <span><i style="color:var(--reserved)">⬤</i> Réservé</span>
        <span>{{ price[2] }} €</span>
        <span>{{ percent[2] }} %</span>
      </li>
      <li>
        <span><i style="color:var(--financed)">⬤</i> Financé</span>
        <span>{{ price[3] }} €</span>
        <span>{{ percent[3] }} %</span>
      </li>
      <li>
        <span><i style="color:var(--acted)">⬤</i> Acté</span>
        <span>{{ price[4] }} €</span>
        <span>{{ percent[4] }} %</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'StatisticsProgramStatus',
  props: {
    numero: Number
  },
  data () {
    return {
    }
  },
  computed: {
    lots: function () {
      return this.$store.getters.get_lots_by_program_num(this.numero)
    },
    quantity: function () {
      var quantity = []
      for (let i = 0; i < 5; i++) {
        quantity.push(this.lots.filter(l => Number(l.status) === i).length)
      }
      return quantity
    },
    lotsbystatus: function () {
      var lotsbystatus = []
      for (let i = 0; i < 5; i++) {
        lotsbystatus.push(this.lots.filter(l => Number(l.status) === i))
      }
      return lotsbystatus
    },
    price: function () {
      var price = []
      this.lotsbystatus.forEach(q => {
        if (q.length > 0) {
          var calc = 0
          q.forEach(l => {
            calc = calc + l.spec.price
          })
          price.push(calc.toLocaleString())
        } else price.push(0)
      })
      return price
    },
    percent: function () {
      var percent = []
      this.quantity.forEach(q => {
        percent.push(Number(((q / this.lots.length) * 100).toFixed(2)))
      })
      if (percent.length < 1) return ''
      return percent
    }
  },
  methods: {
    dasharray: function (val) {
      var second = 100 - this.percent[val]
      return '' + this.percent[val] + ' ' + second
    },
    dashoffset: function (val) {
      if (val === 1) return 125 - (this.percent[0])
      else if (val === 2) return 125 - (this.percent[0] + this.percent[1])
      else if (val === 3) return 125 - (this.percent[0] + this.percent[1] + this.percent[2])
      else if (val === 4) return 125 - (this.percent[0] + this.percent[1] + this.percent[2] + this.percent[3])
    }
  }
}
</script>
