<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-programme"></i>PROGRAMMES</h1>
    </section>
    <section class="programs filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredA">
            <option value="-1">Statut: Tous</option>
            <option v-for="(status, s) in settings.program.status" :value="s" :key="s">{{ status }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice">
          <select v-model="filteredB">
            <option value="-1">Régions: Toutes</option>
            <option v-for="(region, r) in settings.program.region" :value="r" :key="r">{{ region }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterchoice" v-if="list < 2">
          <select v-model="dir">
            <option value="0">Date: Les plus récents</option>
            <option value="1">Chronologie</option>
          </select>
          <i class="icon-select"></i>
        </li>
        <li class="filterlist" :class="{'active': list === 0}" @click="switchList(0)"><i class="icon-grid"></i></li>
        <li class="filterlist" :class="{'active': list === 1}" @click="switchList(1)"><i class="icon-table">☰</i></li>
        <li class="filterlist" :class="{'active': list === 2}" @click="switchList(2)"><i class="icon-list"></i></li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link tag="li" to="/programme/new" class="filteradd"><i class="icon-add"></i> Ajouter un programme</router-link>
      </ul>
    </section>

    <section v-if="list != 2" class="programs grid">

      <section :class="{'list': list === 1,
       'card': list === 0}" v-for="prog in programSearch" :key="prog.id">
        <ProgrammesCard v-if="list === 0" v-bind:prog="prog"/>
        <ProgrammesSmallCard v-if="list === 1" v-bind:prog="prog"/>
      </section>
    </section>

    <p v-if="programSearch.length === 0 && list !== 2" class="card nodata">
      Aucune donnée n’est disponible
    </p>

    <ProgrammesList v-if="list === 2" v-bind:progs="programSearch"/>

  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import ProgrammesCard from '@/components/ProgrammesCard.vue'
import ProgrammesSmallCard from '@/components/ProgrammesSmallCard.vue'
import ProgrammesList from '@/components/ProgrammesList.vue'
import settings from '@/settings.js'

export default {
  name: 'Programmes',
  components: {
    Profile,
    ProgrammesCard,
    ProgrammesSmallCard,
    ProgrammesList
  },
  data () {
    return {
      settings,
      sort: 'numero',
      dir: 0,
      search: '',
      filteredA: -1,
      filteredB: -1
    }
  },
  computed: {
    list: function () {
      return this.$store.getters.meta.menu.program
    },
    programs: function () {
      return this.$store.getters.get_programs()
    },
    sorted: function () {
      var sort = this.sort
      var dir = Number(this.dir)
      return this.programs.slice().sort(function (a, b) {
        if (dir) return (a[sort] > b[sort]) ? 1 : -1
        return (a[sort] < b[sort]) ? 1 : -1
      })
    },
    programStatus: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.sorted
      return this.sorted.filter(el => el.status === fil)
    },
    programRegion: function () {
      var fil = Number(this.filteredB)
      if (fil === -1) return this.programStatus
      return this.programStatus.filter(el => el.location.region === fil)
    },
    programSearch: function () {
      if (this.search) {
        return this.programRegion.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.numero.toString().includes(v) ||
            item.name.toLowerCase().includes(v) ||
            item.location.city.toLowerCase().includes(v) ||
            item.location.district.toLowerCase().includes(v)
          )
        })
      }
      return this.programRegion
    }
  },
  methods: {
    switchList: function (value) {
      this.$store.commit('program_list', value)
    }
  }
}
</script>
