<template>
  <section class="interface">
    <Profile/>
    <section class="title">
      <h1><i class="icon-utilisateur"></i>UTILISATEURS</h1>
    </section>
    <section class="lots filter">
      <ul>
        <li class="filterchoice">
          <select v-model="filteredA">
            <option value="-1">Rôles: Tous</option>
            <option v-for="(role, r) in settings.user.role" :value="r" :key="r">{{ role }}</option>
          </select>
          <i class="icon-select"></i>
        </li>
      </ul>
      <ul>
        <li class="filtersearch"><input v-model="search" type="text" placeholder="Recherche"/><i class="icon-search"></i></li>
        <router-link tag="li" to="/utilisateur/new" class="filteradd"><i class="icon-add"></i> Ajouter un utilisateur</router-link>
      </ul>
    </section>
    <UtilisateursList :users="userSearch"/>
  </section>
</template>

<script>
import Profile from '@/components/Profile.vue'
import UtilisateursList from '@/components/UtilisateursList.vue'
import settings from '@/settings.js'

export default {
  name: 'Utilisateurs',
  components: {
    Profile,
    UtilisateursList
  },
  data () {
    return {
      settings,
      search: '',
      filteredA: -1
    }
  },
  computed: {
    users: function () {
      return this.$store.getters.get_users()
    },
    userRole: function () {
      var fil = Number(this.filteredA)
      if (fil === -1) return this.users
      return this.users.filter(u => u.role === fil)
    },
    userSearch: function () {
      if (this.search) {
        return this.userRole.filter((item) => {
          return this.search.toLowerCase().split(' ').every(v =>
            item.username.toLowerCase().includes(v) ||
            item.meta.firstname.toLowerCase().includes(v) ||
            item.meta.lastname.toLowerCase().includes(v) ||
            item.meta.email.toLowerCase().includes(v) ||
            item.meta.contact.city.toLowerCase().includes(v) ||
            item.meta.contact.phone.toString().toLowerCase().includes(v)
          )
        })
      }
      return this.userRole
    }
  }
}
</script>
