<template>
  <section class="dashpart">

    <!--<div v-html="text"></div>-->
    <section class="interlocutor">
      <ul>
        <li>
            <img :src="url+ 'int1.jpg'" />
            <section>
              <span class="bold"> <br/> </span>
              <span class="bold">Virginie HUBERT</span>
              <span>Responsable S.A.V</span>
              <span><a href="tel:+330298434444" title="Contacter par téléphone">Tel: 02 98 43 44 44</a></span>
            </section>
        </li>
        <li>
            <img :src="url+ 'int2.jpg'" />
            <section>
              <span class="bold">Marie-Adélaide</span>
              <span class="bold">PLUCINSKI-LELIEVRE</span>
              <span>Directrice des ventes</span>
              <span><a href="tel:+330298434444" title="Contacter par téléphone">Tel: 02 98 43 44 44</a></span>
            </section>
        </li>
      </ul>
    </section>

    <div class="msg bold">
      {{ intro }}
    </div>

    <hr/>

    <section class="grid">

      <section class="card programs" v-for="prog in programs" :key="prog.id">

        <ProgrammesCard v-bind:prog="prog"/>

      </section>

      <section class="card">
        <h3 class="title"><i class="bold">🕮</i><span>TÉLÉCHARGEMENTS</span></h3>

        <section class="download">

          <section class="list">
            <small>{{ downloads.name1 }}</small>
            <i>🗎</i> <a v-if="downloads.url1" :href="url + downloads.url1 ">Télécharger</a>
            <span v-else>Aucun fichier</span>
          </section>

          <section class="list">
            <small>{{ downloads.name2 }}</small>
            <i>🗎</i> <a v-if="downloads.url2" :href="url + downloads.url2">Télécharger</a>
            <span v-else>Aucun fichier</span>
          </section>

          <hr/>

          <section class="list">
            <small>{{ downloads.name3 }}</small>
            <i>🗎</i> <a v-if="downloads.url3" :href="url + downloads.url3">Télécharger</a>
            <span v-else>Aucun fichier</span>
          </section>

          <section class="list">
            <small>{{ downloads.name4 }}</small>
            <i>🗎</i> <a v-if="downloads.url4" :href="url + downloads.url4">Télécharger</a>
            <span v-else>Aucun fichier</span>
          </section>

          <section class="list">
            <small>{{ downloads.name5 }}</small>
            <i>🗎</i> <a v-if="downloads.url5" :href="url + downloads.url5">Télécharger</a>
            <span v-else>Aucun fichier</span>
          </section>

        </section>

      </section>
      <section class="card">
        <h3 class="title"><i class="icon-reservation"></i><span>VOS RÉSERVATIONS</span></h3>
        <section class="dashReservations table">
          <ul>
            <li>
              <label title="Programme">PROG</label>
              <label title="Numéro du lot">LOT</label>
              <label title="Status">STATUS</label>
            </li>
          </ul>
          <ul>
            <li class="card td" v-for="(reserv, r) in reservations" :key="r" v-on:dblclick="router(reserv.numero)" >
            <span class="bold">
              <router-link :to="{ path: '/programme/details/' + reserv.program.numero }">
                {{ reserv.program.shortname || reserv.program.numero | capitalize }}
              </router-link>
            </span>
            <span>
              {{ reserv.lot.numero }}
            </span>
            <span class="badge" :class="status(reserv.status)">
              {{ reserv.status | reservation_status }}
              <sup class="" :class="{'expired' : dayLeft(reserv.created) < 0,
              'expired' : dayLeft(reserv.created) === 'exp'}"
                v-if="reserv.status === 1">{{ dayLeft(reserv.created) }}</sup>
            </span>
            </li>
          </ul>
        </section>
      </section>

    </section>

  </section>
</template>

<script>
import settings from '@/settings.js'
import { image } from '@/helpers.js'
import ProgrammesCard from '@/components/ProgrammesCardPartner.vue'

export default {
  name: 'DashboardPartner',
  components: {
    ProgrammesCard
  },
  data () {
    return {
      settings,
      url: settings.upload + 'config' + '/',
      limit: 5
    }
  },
  computed: {
    config: function () {
      return this.$store.getters.get_config()
    },
    highlight: function () {
      return this.config.settings.highlight.filter(h => h > 0)
    },
    programs: function () {
      var progs = []
      this.highlight.forEach(h => {
        progs.push(this.$store.getters.get_program_by_numero(h))
      })
      return progs
    },
    reservations: function () {
      var reservations = this.$store.getters.get_reservations().filter(el => el.user.username === this.me)
      return reservations.slice(0, this.limit)
    },
    intro: function () {
      return this.config.settings.intro
    },
    downloads: function () {
      return this.config.downloads
    },
    me: function () {
      return this.$store.getters.me
    }
  },
  methods: {
    image,
    dayLeft: function (date) {
      const today = Date.now()
      const diff = today - date
      var days = 7 - (diff / (1000 * 3600 * 24))
      if (days < 0) return 'exp'
      return parseFloat(days).toFixed(0)
    },
    status: function (value) {
      return 'st' + value
    }
  }
}
</script>
