import axios from 'axios'
import settings from '@/settings.js'

const Actions = {
  fetchConfig ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'get',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'fetchConfig',
          params: ''
        }
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('status_standby')
            commit('set_config', resp.data.data)
            resolve(resp)
          } else {
            // error
            var mess = 'Fetching `Config` failed!'
            commit('status_error', mess)
            reject(resp.data)
          }
        })
    })
  },
  editConfigWithUploads ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      var handlePayload = {
        uploads: payload.uploads
      }
      if (payload.uploads.length > 0) {
        // manager uploads
        this.dispatch('uploadConfig', handlePayload)
          .then((result) => {
            result.forEach(function (element, key) {
              var name = element[0]
              var filename = element[1]
              var path = 'config/' + filename
              switch (name) {
                case 'url1':
                  payload.config.downloads.url1 = path
                  break
                case 'url2':
                  payload.config.downloads.url2 = path
                  break
                case 'url3':
                  payload.config.downloads.url3 = path
                  break
                case 'url4':
                  payload.config.downloads.url4 = path
                  break
                case 'url5':
                  payload.config.downloads.url5 = path
                  break
              }
            })
            this.dispatch('editConfig', payload.config)
              .then(() => {
                resolve()
              })
              .catch()
          })
          .catch()
      } else {
        this.dispatch('editConfig', payload.config)
          .then(() => {
            resolve()
          })
          .catch()
      }
    })
  },
  editConfig ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      commit('status_loading')
      // call the api
      axios({
        method: 'post',
        url: settings.host,
        params: {
          key: state.auth.key,
          token: state.auth.token,
          function: 'updateConfig',
          params: {
            id: payload.id,
            settings: JSON.stringify(payload.settings),
            bans: JSON.stringify(payload.bans),
            downloads: JSON.stringify(payload.downloads)
          }
        },
        data: ''
      })
        .then(resp => {
          // check header status code
          if (resp.data.status.success) {
            // success
            commit('update_config', payload)
            var mess = 'Config edited to database!'
            commit('status_success', mess)
            resolve()
          } else {
            // error
            var err = 'Failed to edit config in database!'
            commit('status_error', err)
          }
        })
    })
  }
}

export default Actions
