<template>
  <section class="dashboard grid">

    <section class="card table lastconnected">
      <small>Derniers partenaires connectés</small>
      <ul>
        <li><label>DATE</label><label>PARTENAIRE</label></li>
      </ul>
      <ul>
        <li v-for="(last, l) in lasts" :key="l">
          <span class="bold">{{ last.meta.tech.lastconnection | shortDate }}</span>
          <span>{{ last.meta.lastname  }} {{ last.meta.firstname | initial }}</span>
        </li>
      </ul>

    </section>

    <section class="card">
      <small>Traffic mensuel</small>
      200
      <hr/>
      100
      <hr/>
      000
    </section>

    <section class="card">
      <small>Source des connexions</small>
      <p class="small scroll">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br/>
        Duis nostrud exercitation.Lorem ipsum dolor sit amet, consectetur adipiscing elit, incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation
      </p>
    </section>
  </section>
</template>

<script>
export default {
  name: 'DashboardStatsTotal',
  data () {
    return {
    }
  },
  computed: {
    lasts: function () {
      return this.$store.getters.get_users_last_connected.slice(0, 5)
    }
  }
}
</script>
