<template>
  <section class="interface">

    <section class="askfor" v-if="!send">
      <ul>
        <li v-if="!form" @click="form = 1"><i class="icon-add"></i> Demande d'identifiant</li>
        <li v-else @click="form = 0"><i class="icon-delete"></i> Retour au login</li>
      </ul>
    </section>

    <section class="loginForm" v-if="form">

      <section class="title">
        <h1><i class="icon-logo"></i>DEMANDE</h1>
      </section>

      <form class="login" @submit.prevent="request()">
        <p>
          <input v-model="ask.name" id="name" required placeholder="Nom"/>
        </p>
        <p>
          <input v-model="ask.city" id="city" placeholder="Ville"/>
        </p>
        <p>
          <input v-model="ask.email" type="email" required placeholder="Adresse mail"/>
        </p>
        <p>
          <input v-model="ask.tel" type="tel" minlength="10" required placeholder="Téléphone"/>
        </p>
        <p>
          <button type="submit">ENVOYER</button>
        </p>
      </form>

    </section>

    <section class="loginForm" v-else>

      <section class="title">
        <h1><i class="icon-logo"></i>LOGIN</h1>
      </section>

      <form class="login" @submit.prevent="login()">
        <p>
          <input v-model="log.username" type="text" placeholder="Identifiant" required autofocus/>
          <i class="icon-login-user"></i>
        </p>
        <p>
          <input v-model="log.password" type="password" placeholder="Mot de passe" required />
          <i class="icon-login-pass"></i>
        </p>
        <p>
          <button type="submit">CONNEXION</button>
        </p>
      </form>

    </section>

  </section>
</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      log: {
        username: '',
        password: ''
      },
      form: 0,
      send: 0,
      ask: {
        name: '',
        city: '',
        email: '',
        tel: ''
      }
    }
  },
  methods: {
    login: function () {
      var username = this.log.username
      var password = this.log.password
      this.$store.dispatch('login', { username, password })
        .then(() => this.$router.push('/'))
    },
    request: function () {
      var payload = this.ask
      this.$store.dispatch('askForLogin', payload)
        .then(() => {
          this.form = 0
          this.send = 1
        })
    }
  }
}
</script>
